import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';

import { makeStyles } from '@material-ui/core/styles';
import PanelWrapperTitle from './PanelWrapperTitle';

const useStyles = makeStyles({});

const PanelWrapper = props => {
    const {
        children,
        title,
        description,
        headerActions,
        contentClassName,
        headerClassName,
        cardClasses,
        avatar,
        titleClickEvent,
        height
    } = props;
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (e, onClick) => {
        onClick();
        handleClose();
    };

    return (
        <Card data-testid="PanelWrapper__container" classes={cardClasses} style={{ height }}>
            {(title || description) && (
                <CardHeader
                    className={classNames(headerClassName)}
                    data-testid="PanelWrapper__title-container"
                    avatar={
                        avatar && (
                            <Avatar
                                onClick={titleClickEvent}
                                onKeyDown={titleClickEvent}
                                style={{ cursor: 'pointer' }}
                                role="link"
                                tabIndex={0}
                                aria-label="recipe"
                                className={classes.avatar}
                                src={avatar}
                            >
                                B
                            </Avatar>
                        )
                    }
                    action={
                        <>
                            {headerActions.map(a => {
                                if (a.items) {
                                    return (
                                        <React.Fragment key={a.id}>
                                            <Tooltip title={a.hint}>
                                                <IconButton
                                                    aria-controls={`MenuWrapper__menu__${a.id}`}
                                                    aria-haspopup="true"
                                                    onClick={handleClick}
                                                >
                                                    {typeof a.icon === 'string' ? (
                                                        <Icon>{a.icon}</Icon>
                                                    ) : (
                                                        a.icon
                                                    )}
                                                </IconButton>
                                            </Tooltip>
                                            <Menu
                                                id={`MenuWrapper__menu__${a.id}`}
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                            >
                                                {a.items.map(i => (
                                                    <MenuItem
                                                        onClick={e => handleItemClick(e, i.onClick)}
                                                        key={i.id}
                                                    >
                                                        {i.icon && (
                                                            <ListItemIcon>
                                                                {typeof i.icon === 'string' ? (
                                                                    <Icon>{i.icon}</Icon>
                                                                ) : (
                                                                    i.icon
                                                                )}
                                                            </ListItemIcon>
                                                        )}
                                                        <ListItemText primary={i.text} />
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </React.Fragment>
                                    );
                                }

                                return (
                                    <Tooltip key={a.id} title={a.hint}>
                                        <IconButton
                                            aria-controls={`MenuWrapper__action__${a.id}`}
                                            aria-haspopup="true"
                                            onClick={a.onClick}
                                        >
                                            {typeof a.icon === 'string' ? (
                                                <Icon>{a.icon}</Icon>
                                            ) : (
                                                a.icon
                                            )}
                                        </IconButton>
                                    </Tooltip>
                                );
                            })}
                        </>
                    }
                    title={
                        <PanelWrapperTitle titleClickEvent={titleClickEvent}>
                            {title}
                        </PanelWrapperTitle>
                    }
                    subheader={description || null}
                />
            )}
            <CardContent
                data-testid="PanelWrapper__children-container"
                className={classNames(contentClassName)}
            >
                {children}
            </CardContent>
        </Card>
    );
};

PanelWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.node,
    titleClickEvent: PropTypes.func,
    description: PropTypes.node,
    contentClassName: PropTypes.shape({}),
    headerClassName: PropTypes.shape({}),
    cardClasses: PropTypes.shape({}),
    avatar: PropTypes.node,
    headerActions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            hint: PropTypes.string,
            icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
            onClick: PropTypes.func,
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
                    text: PropTypes.string,
                    onClick: PropTypes.func
                })
            )
        })
    ),
    height: PropTypes.string
};

PanelWrapper.defaultProps = {
    title: null,
    titleClickEvent: null,
    description: null,
    contentClassName: null,
    headerClassName: null,
    cardClasses: null,
    avatar: null,
    headerActions: [],
    height: ''
};

export default PanelWrapper;
