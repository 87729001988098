/* eslint-disable no-console */
import { axiosInstance as axios } from './helpers';

const defaultErrorFormatter = ({ response }) => {
    const error = response.data || { code: 500, message: 'Server error!' };
    return {
        code: error.code,
        message: error.title
    };
};

export const customGet = async (url, params, responseFormatter, errorFormatter) => {
    const accessToken = localStorage.getItem('accessToken');
    return axios
        .get(url, {
            headers: { Authorization: `Bearer ${accessToken}` },
            params: params || {}
        })
        .then(responseFormatter || (res => res.data.data))
        .catch(error => {
            console.log(url, params);
            throw errorFormatter ? errorFormatter(error) : defaultErrorFormatter(error);
        });
};

export const customPost = async (url, body, responseFormatter, errorFormatter) => {
    const accessToken = localStorage.getItem('accessToken');
    return axios
        .post(url, body, {
            headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then(responseFormatter || (res => res.data.data))
        .catch(error => {
            console.log(url, body);
            throw errorFormatter ? errorFormatter(error) : defaultErrorFormatter(error);
        });
};

export const customPut = async (url, body, responseFormatter, errorFormatter) => {
    const accessToken = localStorage.getItem('accessToken');
    return axios
        .put(url, body, {
            headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then(responseFormatter || (res => res.data.data))
        .catch(error => {
            console.log(url, body);
            throw errorFormatter ? errorFormatter(error) : defaultErrorFormatter(error);
        });
};

export const customDelete = async (url, params, responseFormatter, errorFormatter) => {
    const accessToken = localStorage.getItem('accessToken');
    return axios
        .delete(url, {
            headers: { Authorization: `Bearer ${accessToken}` },
            params
        })
        .then(responseFormatter || (res => res.data.data))
        .catch(error => {
            console.log(url, params);
            throw errorFormatter ? errorFormatter(error) : defaultErrorFormatter(error);
        });
};
