import moment from 'moment-timezone';
import pick from 'lodash/pick';
import Types from './types';
import { InvalidArgumentError } from '../modules/errors';
import * as firebase from './actionsFirebase';
import { customGet, customPost, customPut, customDelete } from '../services/requests';

export function email2id(email) {
    return email
        .substring(0, email.lastIndexOf('.com'))
        .replace(/\./g, '_')
        .replace(/@/g, '_');
}

export async function fetchGlobalProgram(programId) {
    return async dispatch => {
        return dispatch({
            type: 'FETCH_GLOBAL_PROGRAM',
            async payload() {
                const programNode = await customGet(`/programs/${programId}`);
                const { settings, team } = programNode;
                const { type, parent } = settings;

                if (type === 'pod') {
                    const parentTeam = await customGet(`/programs/${parent}/users`);
                    const podTeam = pick(parentTeam, team);
                    programNode.team = podTeam;
                }

                return {
                    globalProgram: programNode
                };
            }
        });
    };
}

export async function clearGlobalProgram() {
    return async dispatch => {
        return dispatch({
            type: 'CLEAR_PROGRAM'
        });
    };
}

export function pushNewProgramId(newProgramId) {
    customPost('settings/programIds', { programId: newProgramId });
    return true;
}

export function pushNewZendeskUrl(newZendeskUrl) {
    customPost('settings/zendeskUrls', { zendeskUrl: newZendeskUrl });
    return true;
}

export async function retreiveLiveDataByType(dataType, programId, date) {
    if (dataType === 'metrics') {
        const payload = await customGet(`/metrics/live`, { programId, date });
        return payload.metricsData;
    }
    const payload = await customGet(`/productivity/live`, { programId, date });
    return payload.productivityData;
}

export async function fetchPodsForProgram(programId) {
    let pods = await customGet(`/programs/${programId}/settings/pods`);
    if (pods) {
        pods = Object.keys(pods).map(async podId => {
            const podData = await customGet(`/programs/${podId}`);
            return podData;
        });

        return Promise.all(pods).then(progrs => {
            return progrs;
        });
    }
    return [];
}

export async function fetchAdminsForProgram(programId) {
    let admins = await customGet(`/programs/${programId}/settings/admins`);
    if (admins) {
        admins = Object.keys(admins).map(async adminId => {
            const admin = admins[adminId];
            const user = await customGet(`/users/${admin}`);
            return {
                ...user,
                id: adminId,
                userId: admin
            };
        });

        return Promise.all(admins).then(users => {
            return users;
        });
    }
    return [];
}

export async function changeDisplayMode(payload) {
    return async dispatch => {
        return dispatch({ type: Types.DISPLAY_MODE, payload });
    };
}

export async function fetchUserGroups() {
    return async (dispatch, getState) => {
        const state = getState();
        const { settings } = state.programData.globalProgram;
        return dispatch({
            type: 'FETCH_USER_GROUPS',
            payload: customPost(`/programs/${settings.id}/properties/groups`, {
                programSettings: settings,
                property: 'groups'
            })
        });
    };
}

export async function fetchTicketFields() {
    return async (dispatch, getState) => {
        const state = getState();
        const { settings } = state.programData.globalProgram;
        return dispatch({
            type: 'FETCH_TICKET_FIELDS',
            payload: customPost(`/programs/${settings.id}/properties/ticket_fields`, {
                programSettings: settings,
                property: 'ticket_fields'
            })
        });
    };
}

export async function fetchViews() {
    return async (dispatch, getState) => {
        const state = getState();
        const { settings } = state.programData.globalProgram;
        return dispatch({
            type: 'FETCH_VIEWS',
            payload: customPost(`/programs/${settings.id}/properties/views`, {
                programSettings: settings,
                property: 'views'
            })
        });
    };
}

export async function updateSettings(newSettings) {
    return async (dispatch, getState) => {
        const state = getState();
        const { id } = state.programData.globalProgram.settings;
        return dispatch({
            type: 'UPDATE_SETTINGS',
            payload: customPut(`/programs/${id}/settings`, newSettings).then(() => {
                return { ...state.programData.globalProgram.settings, ...newSettings };
            })
        });
    };
}

export async function updateProgramViews(newViews) {
    return async (dispatch, getState) => {
        const state = getState();
        const { id } = state.programData.globalProgram.settings;
        return dispatch({
            type: 'UPDATE_VIEWS',
            payload: customPost(`/programs/${id}/views`, { views: newViews }).then(() => {
                return newViews;
            })
        });
    };
}

export async function getHourlyJobHistory() {
    return async (dispatch, getState) => {
        const state = getState();
        const { id } = state.programData.globalProgram.settings;
        return dispatch({
            type: 'GET_HOURLY_JOB_HISTORY',
            payload: customGet(`/stats/hourly_job/${id}`)
        });
    };
}

export async function removeTeamMember(userId) {
    return async (dispatch, getState) => {
        const state = getState();
        const { settings } = state.programData.globalProgram;
        const { id, pods, admins } = settings;
        const podsIds = pods ? Object.values(pods) : [];

        if (podsIds.length > 0) {
            await Promise.all(
                podsIds.map(async podId => {
                    const podData = await customGet(`/programs/${podId}`);
                    if (typeof podData.settings.admins !== 'undefined') {
                        const userInPodAdmins = Object.entries(podData.settings.admins)
                            .map(([pushKey, rootName]) => ({ pushKey, rootName })) // creating key-pairs array
                            .find(adminEntry => adminEntry.rootName === userId);
                        if (userInPodAdmins) {
                            await customDelete(
                                `programs/${podId}/settings/admins/${userInPodAdmins.pushKey}`
                            );
                        }
                    }

                    const podTeam = podData.team;
                    const newPodTeam = podTeam.filter(
                        userIdWithinPodTeam => userIdWithinPodTeam !== userId
                    );
                    return Promise.all([
                        customPost(`/programs/${podId}/users`, { team: newPodTeam }),
                        customPost(`/programs/${podId}/users/${userId}/remove_from_pod`, {
                            parentId: id
                        })
                    ]);
                })
            );
        }

        const userInProgramAdmins = admins
            ? Object.entries(admins)
                  .map(([pushKey, rootName]) => ({ pushKey, rootName })) // creating key-pairs array
                  .find(adminEntry => adminEntry.rootName === userId)
            : undefined;
        if (userInProgramAdmins) {
            await customDelete(`programs/${id}/settings/admins/${userInProgramAdmins.pushKey}`);
        }

        await customDelete(`/users/${userId}`);
        return dispatch({
            type: 'REMOVE_TEAM_MEMBER',
            payload: customDelete(`/programs/${id}/users/${userId}`).then(() => {
                return userId;
            })
        });
    };
}

export async function removeUserFromPod(userId) {
    return async (dispatch, getState) => {
        const state = getState();
        const { settings, team } = state.programData.globalProgram;
        const { id, parent, admins } = settings;
        const newTeam = Object.keys(team).filter(member => member !== userId);
        const newStoreTeam = { ...team };
        delete newStoreTeam[userId];

        const userInPodAdmins = admins
            ? Object.entries(admins)
                  .map(([pushKey, rootName]) => ({ pushKey, rootName })) // creating key-pairs array
                  .find(adminEntry => adminEntry.rootName === userId)
            : undefined;
        if (userInPodAdmins) {
            await customDelete(`programs/${id}/settings/admins/${userInPodAdmins.pushKey}`);
        }

        return dispatch({
            type: 'REMOVE_USER_FROM_POD',
            payload: Promise.all([
                customPost(`/programs/${id}/users`, { team: newTeam }),
                customPost(`/programs/${id}/users/${userId}/remove_from_pod`, { parentId: parent })
            ]).then(() => newStoreTeam)
        });
    };
}

export async function updateTeamMember(user) {
    return async (dispatch, getState) => {
        const state = getState();
        const { globalProgram } = state.programData;
        const { settings } = globalProgram;
        const { id, parent, type } = settings;
        let updatePath = '';

        if (type === 'program') {
            updatePath = `programs/${id}/users/${user.id}`;
        } else if (type === 'pod') {
            updatePath = `programs/${parent}/users/${user.id}`;
        }

        return dispatch({
            type: 'UPDATE_TEAM_MEMBER',
            payload: customPut(updatePath, { user }).then(() => {
                return user;
            })
        });
    };
}

// erases admin permissions under program/programId/admins
const updateDeletedAdminPermissionsForUser = async newUser => {
    const currentUserData = await firebase
        .getNodePromise(`users/byUserId/${newUser.rootName}`)
        .then(snap => snap.val());

    // admin permissions which were either deleted or downgraded
    const deletedAdminPermissions = Object.values(currentUserData.programs).filter(
        currentProgramPermission =>
            Object.values(newUser.programs).find(newProgramPermission => {
                const currentUserisAdmin =
                    newProgramPermission.programId === currentProgramPermission.programId &&
                    currentProgramPermission.role === 'admin';

                const userIsNoLongerAdmin = newProgramPermission.role !== 'admin';
                if (!currentUserisAdmin || (currentUserisAdmin && userIsNoLongerAdmin))
                    return newProgramPermission;
                return false;
            })
    );

    if (deletedAdminPermissions.length > 0) {
        return Promise.all(
            deletedAdminPermissions.map(async programPermission => {
                const programAdmins = await firebase
                    .getNodePromise(`programs/${programPermission.programId}/settings/admins`)
                    .then(snap => snap.val());

                if (programAdmins) {
                    const userInCurrentAdmins = Object.entries(programAdmins)
                        .map(([pushKey, rootName]) => ({ pushKey, rootName })) // creating key-pairs array
                        .find(adminEntry => adminEntry.rootName === newUser.rootName);

                    if (userInCurrentAdmins) {
                        return customDelete(
                            `programs/${programPermission.programId}/settings/admins/${userInCurrentAdmins.pushKey}`
                        );
                    }
                }
                return true;
            })
        );
    }

    return true;
};

// creates admin permissions under program/programId/admins
const createAdminPermissionsForUser = async newUser => async dispatch =>
    Promise.all(
        Object.keys(newUser.programs).map(async programKey => {
            const programAccess = newUser.programs[programKey];
            const { programId, role } = programAccess;
            const programAdmins = await firebase
                .getNodePromise(`programs/${programId}/settings/admins`)
                .then(snap => snap.val());

            if (
                (!programAdmins && role === 'admin') ||
                (role === 'admin' && !Object.values(programAdmins).includes(newUser.rootName))
            ) {
                return dispatch({
                    type: 'PROMOTE_USER_TO_ADMIN',
                    payload: customPost(`/programs/${programId}/settings/admins`, {
                        userId: newUser.rootName
                    }).then(result => result)
                });
            }
            return true;
        })
    );

/**
 *
 * @param {object} newUser // same as that within users/byUserId/
 */
// this function will help us maintain the program/programId/admins node
const updateAdminPermissions = async newUser => async dispatch =>
    Promise.all([
        updateDeletedAdminPermissionsForUser(newUser),
        dispatch(createAdminPermissionsForUser(newUser))
    ]);

export async function createTeamMember(user, programId) {
    return async (dispatch, getState) => {
        const state = getState();
        const id = programId || state.programData.globalProgram.settings.id;
        const { team, settings } = state.programData.globalProgram;
        const { type, parent, pods } = settings;
        const { email, firstName, lastName } = user;
        const newUser = {
            email,
            firstName,
            lastName,
            role: 'user',
            program: id,
            programs: {
                [id]: {
                    programId: id,
                    role: 'user'
                }
            },
            rootName: user.id
        };

        dispatch(createAdminPermissionsForUser(newUser));

        const accesiblePrograms = user.permissions
            ? user.permissions.filter(prog => prog.access)
            : [];

        // give access to programs
        accesiblePrograms.forEach(prog => {
            newUser.programs[prog.programId] = {
                programId: prog.programId,
                role: prog.role
            };
        });

        const teamUser = { ...user };
        delete teamUser.permissions;
        let podTeam = Object.keys(team);
        let podParent = parent;

        if (pods && Object.keys(pods).includes(id)) {
            podTeam = await firebase.getNodePromise(`programs/${id}/team`).then(snap => snap.val());
            podTeam = podTeam.filter(u => u !== user.id);
            podParent = settings.id;
        }

        if (type === 'pod' || (pods && Object.keys(pods).includes(id))) {
            return dispatch({
                type: 'CREATE_TEAM_MEMBER',
                payload: Promise.all([
                    firebase.updateNodePromise(`programs/${podParent}/team/${user.id}`, teamUser),
                    firebase.setNodePromise(`programs/${id}/team`, [...podTeam, user.id]),
                    firebase.updateNodePromise(`users/byUserId/${user.id}`, newUser)
                ]).then(() => user)
            });
        }

        return dispatch({
            type: 'CREATE_TEAM_MEMBER',
            payload: Promise.all([
                customPut(`/programs/${id}/users/${user.id}`, { user }),
                customPut(`/users/${user.id}`, { userObject: newUser })
            ]).then(() => user)
        });
    };
}

const excludeUserFromTeamSettingsIfNoAccess = async (newUser, currentUserData) => {
    // program access which were either deleted or downgraded
    const deletedFromPrograms = Object.values(currentUserData.programs).filter(
        currentProgramPermission =>
            !Object.values(newUser.programs).find(
                newProgramPermission =>
                    newProgramPermission.programId === currentProgramPermission.programId
            )
    );

    if (deletedFromPrograms.length > 0) {
        return Promise.all(
            deletedFromPrograms.map(async programPermission => {
                const program = await firebase
                    .getNodePromise(`programs/${programPermission.programId}`)
                    .then(snap => snap.val());
                let newTeam;

                if (program.settings.type !== 'pod') {
                    newTeam = Object.keys(program.team)
                        .filter(userIdWithinTeam => userIdWithinTeam !== newUser.rootName)
                        .map(userId => program.team[userId]);
                } else {
                    newTeam = program.team.filter(
                        userIdWithinTeam => userIdWithinTeam !== newUser.rootName
                    );
                }

                return customPost(`/programs/${programPermission.programId}/users`, {
                    team: newTeam
                });
            })
        );
    }
    return true;
};

const includeUserToTeamsItHasNowAccessTo = async (newUser, currentUserData) => {
    const addedUsersToProgram = Object.values(newUser.programs).filter(
        newProgramPermission =>
            !Object.values(currentUserData.programs).find(
                currentProgramPermission =>
                    newProgramPermission.programId === currentProgramPermission.programId
            )
    );

    if (addedUsersToProgram.length > 0) {
        await Promise.all(
            addedUsersToProgram.map(async programPermission => {
                const program = await firebase
                    .getNodePromise(`programs/${programPermission.programId}`)
                    .then(snap => snap.val());
                let newTeam;

                if (program.settings.type !== 'pod') {
                    newTeam = {
                        ...program.team,
                        [newUser.rootName]: newUser.rootName
                    };
                } else {
                    newTeam = program.team.concat(newUser.rootName);
                }

                return customPost(`/programs/${programPermission.programId}/users`, {
                    team: newTeam
                });
            })
        );
    }
};

/**
 *
 * @param {object} newUser // same as that within users/byUserId/
 */
// this function will help us maintain the program/programId/team object
// for whenever a permissions update through the user dialog is made
const updateProgramsAccess = async newUser => {
    const currentUserData = await firebase
        .getNodePromise(`users/byUserId/${newUser.rootName}`)
        .then(snap => snap.val());
    return Promise.all([
        excludeUserFromTeamSettingsIfNoAccess(newUser, currentUserData),
        includeUserToTeamsItHasNowAccessTo(newUser, currentUserData)
    ]);
};

export async function updateUserTeamMember(user, programId) {
    return async (dispatch, getState) => {
        const state = getState();
        const id = programId;
        const { team, settings } = state.programData.globalProgram;
        const { type, parent, pods } = settings;
        const { email, firstName, lastName, previousData } = user;

        const newUser = {
            email,
            firstName,
            lastName,
            role: 'user',
            program: id,
            programs: {},
            rootName: user.id
        };

        const accesiblePrograms = user.permissions
            ? user.permissions.filter(prog => prog.access)
            : [];

        // give access to programs
        accesiblePrograms.forEach(prog => {
            newUser.programs[prog.programId] = {
                programId: prog.programId,
                role: prog.role
            };
        });

        const teamUser = { ...user };
        delete teamUser.permissions;
        delete teamUser.previousData;

        let podTeam = team;
        let podParent = parent;

        if (pods && Object.keys(pods).includes(id)) {
            podTeam = await firebase.getNodePromise(`programs/${id}/team`).then(snap => snap.val());
            podTeam = podTeam.filter(u => u !== user.id);
            podParent = settings.id;
        }

        // Validate the previous program is not the parent program
        let deleteFromPrevious = false;
        if (podParent !== previousData.program) {
            deleteFromPrevious = programId !== previousData.program;
        }

        if (deleteFromPrevious) {
            await firebase.getNodePromise(`programs/${previousData.program}/team`).then(snap => {
                const data = snap.val();

                return firebase.setNodePromise(
                    `programs/${previousData.program}/team`,
                    data.filter(u => u !== user.id)
                );
            });
        }
        if (type === 'pod' || (pods && Object.keys(pods).includes(id))) {
            if (type === 'program') {
                return dispatch({
                    type: 'UPDATE_TEAM_MEMBER',
                    payload: Promise.all([
                        firebase.updateNodePromise(
                            `programs/${podParent}/team/${user.id}`,
                            teamUser
                        ),
                        firebase.updateNodePromise(`users/byUserId/${user.id}`, newUser),
                        firebase.setNodePromise(`programs/${id}/team`, [...podTeam, user.id])
                    ]).then(() => user)
                });
            }
            return dispatch({
                type: 'UPDATE_TEAM_MEMBER',
                payload: Promise.all([
                    firebase.updateNodePromise(`programs/${podParent}/team/${user.id}`, teamUser),
                    firebase.updateNodePromise(`users/byUserId/${user.id}`, newUser)
                ]).then(() => user)
            });
        }
        return dispatch({
            type: 'UPDATE_TEAM_MEMBER',
            payload: Promise.all([
                firebase.updateNodePromise(`programs/${id}/team/${user.id}`, teamUser),
                firebase.updateNodePromise(`users/byUserId/${user.id}`, newUser)
            ]).then(() => user)
        });
    };
}

async function createPod(data) {
    const { id, parent } = data.settings;
    return customPost('programs', {
        program: data,
        programId: id,
        parentId: parent
    });
}

export async function createNewPod(podData) {
    return async (dispatch, getState) => {
        const state = getState();
        const { id } = state.programData.globalProgram.settings;
        const newPod = {
            ...podData
        };
        newPod.settings.parent = id;
        newPod.settings.startDate = moment().format('MM_DD_YY');
        newPod.settings.type = 'pod';
        newPod.settings.id = podData.settings.prettyName.replace(/ /g, '_').toLowerCase();
        return dispatch({
            type: 'CREATE_NEW_POD',
            payload: createPod(newPod).then(() => newPod)
        });
    };
}

export async function promoteUserToAdmin(programId, userId) {
    return async dispatch => {
        return dispatch({
            type: 'PROMOTE_USER_TO_ADMIN',
            payload: customPost(`/programs/${programId}/settings/admins/${userId}`).then(() => true)
        });
    };
}

export async function removeAdminAccess(programId, userId) {
    return async dispatch => {
        return dispatch({
            type: 'REMOVE_ADMIN',
            payload: customDelete(`/programs/${programId}/settings/admins/${userId}`).then(
                () => true
            )
        });
    };
}

export async function archivePod(podId) {
    if (!podId) {
        throw new InvalidArgumentError('PodId is required!');
    }
    return async dispatch => {
        return dispatch({
            type: 'DELETE_POD',
            payload: customPost(`/programs/${podId}/archive`)
        });
    };
}

export async function unarchivePod(podId) {
    if (!podId) {
        throw new InvalidArgumentError('PodId is required!');
    }
    return async dispatch => {
        return dispatch({
            type: 'DELETE_POD',
            payload: customPost(`/programs/${podId}/unarchive`)
        });
    };
}

export async function getProgramExternalUsers(programId) {
    const allExternalUsers = await customGet('users/external');
    if (!allExternalUsers) return [];
    return Object.values(allExternalUsers).filter(
        externalUser => externalUser.program === programId
    );
}

export const getProgramData = async programId => customGet(`programs/${programId}`);

export async function runHourlyJob(globalProgram) {
    const options = {
        programId: globalProgram.settings.id,
        timestamp: moment().unix()
    };

    return customPost(`/jobs/productivity_fetch`, options);
}
