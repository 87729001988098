import _clone from 'lodash/clone';
import formatToFusionChart from '../fusionChartsFormatters';

const getSeries = (dayData, hours, dayKey, viewLoadType, displayMode) => {
    const queueLoads = {};

    hours.forEach(hour => {
        let index = dayData[hour];
        index = Object.keys(index).map(key => {
            return index[key];
        });

        // filter out queue load
        index = index.find(o => o.type === 'queueLoad');

        if (!index) return;

        const queueNames = Object.keys(index);

        queueNames.forEach(queueName => {
            if (queueName === 'type') return;

            let queueLoad = index[queueName];

            if (typeof queueLoad === 'object' && queueLoad !== null)
                queueLoad = queueLoad[viewLoadType];

            if (queueLoad === 'NaN') queueLoad = null;

            if (displayMode === 'range') {
                if (!queueLoads[queueName]) queueLoads[queueName] = 0;
                queueLoads[queueName] = queueLoad;
            } else {
                if (!queueLoads[queueName]) queueLoads[queueName] = [];
                queueLoads[queueName].push(queueLoad);
            }
        });
    });

    return {
        dayKey,
        dataLine: hours,
        series: {
            ...queueLoads
        }
    };
};

const buildDayObject = (dayKey, dayData, viewLoadType, displayMode) => {
    if (!dayData || (dayData && !dayData.byHour)) return null;
    const hours = Object.keys(dayData.byHour);
    const dayObject = getSeries(dayData.byHour, hours, dayKey, viewLoadType, displayMode);

    return dayObject;
};

const processAllDays = (productivityData, viewLoadType, displayMode, startDate, endDate) => {
    const groupedData = [];

    if (displayMode === 'range') {
        if (!productivityData || Object.keys(productivityData).length === 1) return null;
        const days = Object.keys(productivityData).filter(
            day => day >= startDate && day <= endDate
        );
        const series = {};

        days.forEach(dayKey => {
            const dayData = productivityData[dayKey];
            if (!dayData || !dayData.queueLoad) return;
            const queueValues = dayData.queueLoad;
            Object.keys(queueValues).forEach(queueKey => {
                const newValue = queueValues[queueKey][viewLoadType];

                if (typeof newValue === 'undefined') return;

                if (typeof series[queueKey] === 'undefined') series[queueKey] = [newValue];
                else if (typeof series[queueKey] !== 'undefined') series[queueKey].push(newValue);
            });
        });

        if (Object.keys(series).length === 0) {
            return null;
        }

        return {
            dataLine: days.map(dayKey =>
                _clone(dayKey)
                    .replace(/_/g, '/')
                    .slice(0, 5)
            ),
            dayKey: days[0],
            series
        };
    }
    const days = Object.keys(productivityData);
    days.forEach(dayKey => {
        const dayData = productivityData[dayKey];
        const formattedDayData = buildDayObject(dayKey, dayData, viewLoadType, displayMode);
        if (formattedDayData) {
            groupedData.push(formattedDayData);
        }
    });

    return groupedData;
};

const formatChartData = (programData, viewLoadType, displayMode) => {
    const { productivityData } = programData;
    if (!productivityData) return null;
    const filteredProductivityData = { ...productivityData };

    const { startDate, endDate } = programData;
    const groupedData = processAllDays(
        filteredProductivityData,
        viewLoadType,
        displayMode,
        startDate,
        endDate
    );
    let formattedData;

    if (displayMode === 'range') {
        formattedData = formatToFusionChart([groupedData], 'heatmap');
    } else if (groupedData) formattedData = formatToFusionChart(groupedData, 'heatmap');

    return formattedData;
};

export default formatChartData;
