const initialState = {
    pending: false,
    error: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'RUN_HOURLY_JOB_PENDING':
            return {
                ...state,
                pending: true,
                error: ''
            };
        case 'RUN_HOURLY_JOB_FULFILLED':
            return {
                ...state,
                pending: false,
                error: ''
            };
        case 'RUN_HOURLY_JOB_REJECTED':
            return {
                ...state,
                pending: false,
                error: 'Error running hourly job.'
            };

        default:
            return state;
    }
};
