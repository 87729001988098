import React from 'react';
import ComingSoon from 'Containers/ComingSoon';
import LoggedInNotFoundPage from 'Components/NotFoundPage';
import Login from 'Containers/Login';
import { Redirect } from 'react-router-dom';

import authRoles from '../auth/authRoles';

export const routes = [
    {
        path: '/login',
        component: Login,
        auth: authRoles.guest
    },
    {
        path: '/noaccess',
        exact: true,
        component: React.lazy(() => import('Components/NoAccessProgramPage')),
        auth: authRoles.noAccess
    },
    {
        path: '/',
        component: React.lazy(() => import('Components/ProgramRedirect')),
        exact: true,
        auth: authRoles.user
    },
    {
        path: '/program/:programId',
        component: React.lazy(() => import('Containers/ProgramPage')),
        auth: authRoles.user,
        routes: [
            {
                path: '/program/:programId',
                exact: true,
                // eslint-disable-next-line react/prop-types
                component: ({ match }) => {
                    // eslint-disable-next-line react/prop-types
                    return <Redirect to={`/program/${match.params.programId}/livestats`} />;
                }
            },
            {
                path: '/program/:programId/livestats',
                component: React.lazy(() => import('Containers/Statistics')),
                routes: [
                    {
                        path: '/program/:programId/livestats',
                        exact: true,
                        // eslint-disable-next-line react/prop-types
                        component: ({ match }) => {
                            return (
                                <Redirect
                                    // eslint-disable-next-line react/prop-types
                                    to={`/program/${match.params.programId}/livestats/general`}
                                />
                            );
                        }
                    },
                    {
                        path: '/program/:programId/livestats/general',
                        component: React.lazy(() => import('Containers/Statistics/General')),
                        auth: authRoles.user
                    },
                    {
                        path: '/program/:programId/livestats/customFields',
                        component: React.lazy(() =>
                            import('Containers/CustomFields/CustomFieldsPage')
                        ),
                        auth: authRoles.user
                    },
                    {
                        path: '/program/:programId/livestats/team',
                        exact: true,
                        component: React.lazy(() => import('Containers/Statistics/Team')),
                        auth: authRoles.user
                    },
                    {
                        path: '/program/:programId/livestats/team/:emailId',
                        exact: true,
                        component: React.lazy(() => import('Components/IndividualDashboard')),
                        auth: authRoles.user
                    }
                ]
            },
            {
                path: '/program/:programId/settings',
                component: React.lazy(() => import('Containers/Settings')),
                auth: authRoles.admin,
                routes: [
                    {
                        path: '/program/:programId/settings',
                        exact: true,
                        // eslint-disable-next-line react/prop-types
                        component: ({ match }) => {
                            return (
                                <Redirect
                                    // eslint-disable-next-line react/prop-types
                                    to={`/program/${match.params.programId}/settings/general`}
                                />
                            );
                        }
                    },
                    {
                        path: '/program/:programId/settings/general',
                        component: React.lazy(() => import('Containers/Settings/General')),
                        auth: authRoles.user
                    },
                    {
                        path: '/program/:programId/settings/metrics',
                        component: React.lazy(() => import('Containers/Settings/Metrics')),
                        auth: authRoles.user
                    },
                    {
                        path: '/program/:programId/settings/team',
                        component: React.lazy(() => import('Containers/Settings/Team')),
                        auth: authRoles.user
                    },
                    {
                        path: '/program/:programId/settings/pods',
                        component: React.lazy(() => import('Containers/Settings/Pods')),
                        auth: authRoles.user
                    },
                    {
                        path: '/program/:programId/settings/jobs',
                        component: React.lazy(() => import('Containers/Settings/Jobs')),
                        auth: authRoles.user
                    },
                    {
                        path: '/program/:programId/settings/external',
                        component: React.lazy(() => import('Containers/Settings/External')),
                        auth: authRoles.user
                    }
                ]
            },
            {
                path: '/program/:programId/forecast',
                component: ComingSoon,
                auth: authRoles.user
            },
            {
                path: '/program/:programId/support',
                component: React.lazy(() => import('Containers/HelpPage')),
                routes: [
                    {
                        path: '/program/:programId/support',
                        exact: true,
                        // eslint-disable-next-line react/prop-types
                        component: ({ match }) => {
                            return (
                                <Redirect
                                    // eslint-disable-next-line react/prop-types
                                    to={`/program/${match.params.programId}/support/FAQ`}
                                />
                            );
                        }
                    },
                    {
                        path: '/program/:programId/support/FAQ',
                        component: React.lazy(() => import('Containers/HelpPage/FAQ')),
                        auth: authRoles.user
                    }
                ]
            }
        ]
    },
    {
        path: '',
        component: LoggedInNotFoundPage,
        auth: authRoles.user
    }
];

export default routes;
