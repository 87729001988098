import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/analytics';

const AccessData = {
    apiKey: process.env.FB_API_KEY,
    authDomain: process.env.FB_AUTH_DOMAIN,
    databaseURL: process.env.FB_DB_URL,
    projectId: process.env.FB_PROJECT_ID,
    storageBucket: process.env.FB_STORAGE_BUCKET,
    messagingSenderId: process.env.FB_MESSAGE_SENDER_ID,
    appId: process.env.FB_APP_ID
};

firebase.initializeApp(AccessData);
export const FB = firebase;
export const DB = firebase.database();
export const STORAGE = firebase.storage();
export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();
if (location.hostname === 'localhost') {
    DB.useEmulator('localhost', 9000);
    auth.useEmulator('http://localhost:9099');
}
// this re-logs with popup
export async function refreshAccessToken(loggedUserData) {
    const newUserData = { ...loggedUserData };

    // return the new token
    return firebase
        .auth()
        .signInWithPopup(provider)
        .then(result => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            return result.credential.accessToken;
        })
        .catch(error => {
            // eslint-disable-next-line no-console
            console.warn('error', error);
            newUserData.googleAccessToken = '';
            return '';
        });
}

export async function get(path) {
    let res = await DB.ref(`/${path}`).once('value');
    if (res) res = res.val();
    return res;
}

export async function getWithStartAndEnd(path, startAtValue, endAtValue) {
    let res = await DB.ref(`/${path}`)
        .orderByKey()
        .startAt(startAtValue)
        .endAt(endAtValue)
        .once('value');
    if (res) res = res.val();
    return res;
}

export function write(path, data) {
    DB.ref(`/${path}`).set(data);
    return true;
}

export function remove(path) {
    DB.ref(`/${path}`).remove();
    return true;
}

export function push(path, data) {
    DB.ref(`/${path}`).push(data);
    return true;
}

export async function getByChildProperty(path, childPropertyName, propertyValue) {
    let res = await DB.ref(`/${path}`)
        .orderByChild(childPropertyName)
        .equalTo(propertyValue)
        .once('value');
    if (res) res = res.val();
    return res;
}

export async function update(path, data) {
    await DB.ref(`/${path}`).update(data);
    return DB.ref(`/${path}`).once('value');
}

export function storeFile(path, file) {
    return STORAGE.ref(path).put(file);
}

export function getFileURL(path) {
    return STORAGE.ref(path).getDownloadURL();
}

// PD: "Node" refers to a firebase node, not to NodeJS
export function getNodePromise(path) {
    return DB.ref(`/${path}`).once('value');
}

export function updateNodePromise(path, data) {
    return DB.ref(`/${path}`).update(data);
}

export function setNodePromise(path, data) {
    return DB.ref(`/${path}`).set(data);
}

export function deleteNodePromise(path) {
    return DB.ref(`/${path}`).remove();
}

export function pushNodePromise(path, value) {
    return DB.ref(`/${path}`).push(value);
}
export function multipleUpdates(updates) {
    return DB.ref().update(updates);
}

export function getRef(path) {
    return DB.ref(`/${path}`);
}
