/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';

import isString from 'lodash/isString';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

/**
 * Component used at `PanelWrapper` to render the title
 */
const PanelWrapperTitle = ({ children, titleClickEvent }) => {
    if (!isString(children)) return <React.Fragment>{children}</React.Fragment>;

    return titleClickEvent ? (
        <Link
            onClick={titleClickEvent}
            style={{ textDecoration: 'none' }}
            data-testid="PanelWrapperTitle__link"
        >
            <Typography variant="h6" data-testid="PanelWrapperTitle__text-wrapper">
                {children}
            </Typography>
        </Link>
    ) : (
        <Typography variant="h6" data-testid="PanelWrapperTitle__text-wrapper">
            {children}
        </Typography>
    );
};

PanelWrapperTitle.propTypes = {
    /** If children is string it applies styling, else it renders it inside a fragment */
    children: PropTypes.node.isRequired,
    /** If children is string, defines the action triggered on click */
    titleClickEvent: PropTypes.func
};

PanelWrapperTitle.defaultProps = {
    titleClickEvent: undefined
};

export default PanelWrapperTitle;
