/**
 * For managing program and permissions (roles)
 * This redirects if user has no specified access to a program, insufficient roles, permissions, etc...
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { matchRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { routes } from '../routers/AppRoutes';
import { analytics } from '../actions/actionsFirebase';

export const hasPermission = (authArr, userRole) => {
    /**
     * If auth array is not defined
     * Pass and allow
     */
    if (authArr === null || authArr === undefined) {
        return true;
    }
    if (authArr.length === 0) {
        /**
         * if auth array is empty means,
         * allow only user role is guest (null or empty[])
         */
        return !userRole || userRole.length === 0;
    }
    /**
     * Check if user has grants
     */
    /*
            Check if user role is array,
            */
    if (userRole && Array.isArray(userRole)) {
        return authArr.some(r => userRole.indexOf(r) >= 0);
    }

    /*
            Check if user role is string,
            */
    return authArr.includes(userRole);
};

class Authorization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accessGranted: true,
            routes
        };
    }

    componentDidMount() {
        this.checkRedirect();
    }

    static getDerivedStateFromProps(props, state) {
        const { location, role } = props;
        const { pathname } = location;

        const matchedRoutes = matchRoutes(state.routes, pathname);
        const matched =
            matchedRoutes.length === 1 ? matchedRoutes[0] : matchedRoutes[matchedRoutes.length - 1];
        const permissionGranted = hasPermission(matched.route.auth, role);
        const accessGranted = matched ? permissionGranted : true;
        return {
            accessGranted
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { accessGranted } = this.state;
        return nextState.accessGranted !== accessGranted;
    }

    componentDidUpdate() {
        this.checkRedirect();
    }

    checkRedirect = () => {
        const { accessGranted } = this.state;

        if (!accessGranted) {
            this.redirectRoute();
        }
    };

    redirectRoute() {
        const { location, role, history } = this.props;
        const { pathname, state } = location;
        let redirectUrl = state && state.redirectUrl ? state.redirectUrl : '/';
        if (redirectUrl === '/logout') redirectUrl = '/';
        /*
        User is guest
        Redirect to Login Page
        */
        if (!role || role.length === 0) {
            history.push({
                pathname: '/login',
                state: { redirectUrl: pathname }
            });
        } else if (pathname === '/login') {
            /*
        User is member
        User just logged in
        Redirect to dashboard or redirectUrl
        */
            history.push({
                pathname: redirectUrl
            });
        } else {
            /*
        User is member
        User must be on unAuthorized page
        Redirect to No Access Page
        */
            history.push({
                pathname: '/noaccess',
                state: { redirectUrl: pathname }
            });
        }
    }

    render() {
        const { children, userId } = this.props;
        const { accessGranted } = this.state;

        if (accessGranted) {
            analytics.setUserId(userId);
        }

        return accessGranted ? <React.Fragment>{children}</React.Fragment> : null;
    }
}

Authorization.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
        .isRequired,
    role: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.element)]).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        state: PropTypes.shape({
            redirectUrl: PropTypes.string.isRequired
        })
    }).isRequired,
    globalProgram: PropTypes.shape({
        settings: PropTypes.shape({}).isRequired,
        team: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
    }),
    userId: PropTypes.string
};

Authorization.defaultProps = {
    globalProgram: null,
    userId: null
};

const mapStateToProps = ({ loggedUser, programData }) => {
    return {
        role: loggedUser.role,
        userId: loggedUser.id,
        email: loggedUser.email,
        globalProgram: programData.globalProgram
    };
};

export default withRouter(connect(mapStateToProps)(Authorization));
