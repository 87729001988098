import moment from 'moment';
import { CsvBuilder } from 'filefy';
import dataByProperty from 'Actions/dataByProperty';
import {
    retreiveLiveDataByType,
    formatTeamMetrics,
    formatQueueData,
    getAppSettings,
    getUserData
} from 'Actions';
import Types from 'Actions/types';
import { FB as firebase } from 'Actions/actionsFirebase';

export function median(valuesArray) {
    let values = valuesArray;
    values.sort((a, b) => a - b);

    values = values.filter(value => value > 0);

    if (values.length === 0) return 0;

    const half = Math.floor(values.length / 2);

    if (values.length % 2) return values[half];
    return (values[half - 1] + values[half]) / 2.0;
}

export function createArray(len, itm) {
    let newLen = len;
    let arr1 = [itm];
    let arr2 = [];
    while (newLen > 0) {
        if (newLen % 2 !== 0) arr2 = arr2.concat(arr1);
        arr1 = arr1.concat(arr1);
        newLen = Math.floor(newLen * 0.5);
    }
    return arr2;
}

export function camelCaseToTitle(string) {
    let newString;
    for (let i = 0; i < string.length; i += 1) {
        if (i === 0) newString = string.charAt(i).toUpperCase();
        else if (string.charAt(i).toUpperCase() === string.charAt(i)) {
            newString = `${newString} ${string.charAt(i)}`;
        } else if (
            (i === string.length - 1 && string.charAt(i) !== ' ') ||
            i !== string.length - 1
        ) {
            newString += string.charAt(i);
        }
    }
    return newString;
}

export function textToTitle(string) {
    let newString;
    for (let i = 0; i < string.length; i += 1) {
        if (i === 0) newString = string.charAt(i).toUpperCase();
        else if (string.charAt(i - 1) === ' ') {
            newString += string.charAt(i).toUpperCase();
        } else if (
            (i === string.length - 1 && string.charAt(i) !== ' ') ||
            i !== string.length - 1
        ) {
            newString += string.charAt(i);
        }
    }
    return newString;
}

export function isBetweenDates(dayKey, startDate, endDate) {
    const dateFormat = dayKey !== undefined && dayKey.indexOf('/') !== -1 ? 'MM/DD' : 'MM_DD_YYYY';

    const momentDaykey = moment(dayKey, dateFormat);
    const isAfter = momentDaykey.isAfter(moment(startDate, dateFormat));
    const isEqualToStart = momentDaykey.isSame(moment(startDate, dateFormat));
    const isBefore = momentDaykey.isBefore(moment(endDate, dateFormat));
    const isEqualToEnd = momentDaykey.isSame(moment(endDate, dateFormat));

    if ((isAfter || isEqualToStart) && (isBefore || isEqualToEnd)) return dayKey;
    return false;
}

export function getLastObjectFromArray(array, positionsBefore = 1) {
    if (!Array.isArray(array)) return undefined;
    return array[array.length - positionsBefore];
}

// Test this function
export function sumObjectsByKey(...objs) {
    return objs.reduce((a, b) => {
        const newA = {};
        Object.keys(b).forEach(k => {
            if (Object.prototype.hasOwnProperty.call(b, k)) {
                const existingValue = Number(a[k]);
                const newValue = Number(b[k]);
                newA[k] = (existingValue || 0) + newValue;
            }
        });
        return newA;
    }, {});
}

export function camelCaseToWords(camelCasedWord) {
    return camelCasedWord
        .replace(/([A-Z])/g, ' $1')

        .replace(/^./, str => {
            return str.toUpperCase();
        });
}

export function stringToCamelCase(str) {
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        })
        .replace(/\s+/g, '');
}

async function getTeamMetrics(args) {
    const { programData, result, mode, arrayOfProperties } = args;
    const returnData = await formatTeamMetrics.formatChartData(
        programData,
        result,
        mode,
        arrayOfProperties
    );
    return returnData;
}

async function getQueueData(args) {
    const { programData, queueLoadType, result } = args;
    const returnData = await formatQueueData.formatChartData(
        programData,
        queueLoadType,
        'live',
        result
    );
    return returnData;
}

const formattingMethods = {
    team: getTeamMetrics,
    queue: getQueueData
};

export async function dataPlotClick(args) {
    const {
        dataObj,
        programData,
        dataType,
        drilldownFunction,
        startDate,
        type,
        openDrilldownModal,
        fullName,
        email,
        queueLoadType,
        format
    } = args;

    const { globalProgram } = programData;
    const { id } = globalProgram.settings;

    const mode = 'live';

    // extracting year from the startDate
    const date = dataObj.categoryLabel.replace(/\//g, '_') + startDate.slice(-3);

    let result = await retreiveLiveDataByType(dataType, id, date);
    if (format)
        result = await formattingMethods[format]({
            programData,
            result,
            mode,
            arrayOfProperties: dataByProperty[type],
            queueLoadType
        });

    const drilledDownData = drilldownFunction(
        globalProgram.team,
        result,
        mode,
        date,
        undefined,
        email,
        fullName
    );
    openDrilldownModal(type, drilledDownData);
}

export const exportFile = (fileName, columns, rows) =>
    new CsvBuilder(`${fileName}.csv`)
        .setColumns(columns)
        .addRows(rows)
        .exportFile();

export const getUserWithinProductivity = (userDataArray, team, uniqueUsersPerDay, accumulator) => {
    const userData = userDataArray[1];
    if (typeof userData.email !== 'string') return null;

    const userWithinTeam = Object.keys(team).find(
        userTeamKey => team[userTeamKey].email === userData.email
    );
    const userAlreadyAccumulated = accumulator.find(
        accumulatedUserData => accumulatedUserData.email === userData.email
    );

    const userAlreadyAddedToThisDay = uniqueUsersPerDay.find(
        alreadyIncludedUser => alreadyIncludedUser.email === userData.email
    );
    if (!userWithinTeam && !userAlreadyAccumulated && !userAlreadyAddedToThisDay) {
        const alias = userData.email.split('@')[0];
        const aliasHasFirstAndLastName = alias.includes('.');
        const aliasChunks = alias.split('.');

        const firstName = aliasHasFirstAndLastName
            ? aliasChunks[0].charAt(0).toUpperCase() + aliasChunks[0].slice(1)
            : alias;
        const lastName = aliasHasFirstAndLastName
            ? aliasChunks[1].charAt(0).toUpperCase() + aliasChunks[1].slice(1)
            : '';

        return {
            email: userData.email,
            firstName,
            lastName
        };
    }

    if (userWithinTeam && !userAlreadyAccumulated && !userAlreadyAddedToThisDay) {
        const { firstName, lastName, profilePicture } = team[userWithinTeam];
        return {
            email: userData.email,
            firstName,
            lastName,
            profilePicture
        };
    }
    return null;
};

export const resyncAppSettings = () => async dispatch => {
    const user = firebase.auth().currentUser;
    const setUserData = {
        tokenEmissionTimestamp: moment().format(),
        email: user,
        name: user.displayName,
        verified_email: user.email_verified,
        picture: user.photoURL,
        ...user
    };
    const userData = await getUserData(setUserData);
    const appSettings = await getAppSettings(userData);
    dispatch({ type: Types.APPLICATION_SETTINGS, payload: appSettings });
};
