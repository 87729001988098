const Types = {
    GRAPH_DATA: 'GRAPH_DATA',
    PROGRAM_DATA: 'PROGRAM_DATA',
    LOGGED_USER: 'LOGGED_USER',
    APPLICATION_SETTINGS: 'APPLICATION_SETTINGS',
    UPDATE_FIREBASE_USER: 'UPDATE_FIREBASE_USER',
    UPDATE_USER: 'UPDATE_USER',
    DISPLAY_MODE: 'DISPLAY_MODE',
    FETCH_GLOBAL_PROGRAM_PENDING: 'FETCH_GLOBAL_PROGRAM_PENDING',
    FETCH_GLOBAL_PROGRAM_SUCCESS: 'FETCH_GLOBAL_PROGRAM_SUCCESS',
    FETCH_GLOBAL_PROGRAM_FAILURE: 'FETCH_GLOBAL_PROGRAM_FAILURE',
    CHANGE_DATE: 'CHANGE_DATE',
    FETCH_PRODUCTIVITY_DATA_PENDING: 'FETCH_PRODUCTIVITY_DATA_PENDING',
    FETCH_PRODUCTIVITY_DATA_FULFILLED: 'FETCH_PRODUCTIVITY_DATA_FULFILLED',
    FETCH_PRODUCTIVITY_DATA_REJECTED: 'FETCH_PRODUCTIVITY_DATA_REJECTED',
    CLEAR_PRODUCTIVITY_DATA: 'CLEAR_PRODUCTIVITY_DATA',
    CLEAR_GRAPH_DATA: 'CLEAR_GRAPH_DATA',
    SET_VIEW_LOAD_DATA: 'SET_VIEW_LOAD_DATA',
    FETCH_METRICS_PENDING: 'FETCH_METRICS_PENDING',
    FETCH_METRICS_SUCCESS: 'FETCH_METRICS_SUCCESS',
    FETCH_METRICS_FAILURE: 'FETCH_METRICS_FAILURE',
    CLEAR_METRICS: 'CLEAR_METRICS',
    SET_RESOLUTION_TIME_DATA: 'SET_RESOLUTION_TIME_DATA',
    SET_REPLY_TIME_DATA: 'SET_REPLY_TIME_DATA',
    SET_CUSTOM_FIELDS_DATA: 'SET_CUSTOM_FIELDS_DATA',
    FETCH_DAILY_NOTIFICATIONS: 'FETCH_DAILY_NOTIFICATIONS',
    FETCH_UNREAD_NOTIFICATIONS: 'FETCH_UNREAD_NOTIFICATIONS',
    CLEAR_UNREAD_NOTIFICATIONS: 'CLEAR_UNREAD_NOTIFICATIONS',
    CREATE_EXTERNAL_USER_FULFILLED: 'CREATE_EXTERNAL_USER_FULFILLED',
    CREATE_EXTERNAL_USER_FAILURE: 'CREATE_EXTERNAL_USER_FAILURE',
    CREATE_EXTERNAL_USER_PENDING: 'CREATE_EXTERNAL_USER_PENDING',
    CREATE_EXTERNAL_USER: 'CREATE_EXTERNAL_USER',
    SET_PRODUCTIVITY_FILTERS: 'SET_PRODUCTIVITY_FILTERS',
    SET_SORT_TEAM_BY: 'SET_SORT_TEAM_BY'
};

export default Types;
