import moment from 'moment-timezone';

import * as firebaseActions from './actionsFirebase';
import Types from './types';
import { getUserData, getAppSettings } from './actionsUser';
import { InvalidArgumentError } from '../modules/errors';

export async function login(user) {
    if (!user) throw new InvalidArgumentError('Parameter user cannot be null or undefined');

    const entryUser = {
        tokenEmissionTimestamp: moment().format(),
        email: user,
        name: user.displayName,
        verified_email: user.email_verified,
        picture: user.photoURL,
        ...user
    };
    return async dispatch => {
        const userData = await getUserData(entryUser);

        if (!userData) {
            return dispatch({
                type: Types.LOGGED_USER,
                payload: null
            });
        }

        const appSettings = await getAppSettings(userData);

        if (!appSettings)
            // eslint-disable-next-line no-console
            console.error(
                'Could not get user settings. If you see this error, please report it to the Beacon Team.'
            );

        dispatch({ type: Types.APPLICATION_SETTINGS, payload: appSettings });
        return dispatch({
            type: Types.LOGGED_USER,
            payload: { ...userData, allPrograms: appSettings.hasAccess } || {
                noDataFound: true
            }
        });
    };
}

export async function signOut() {
    return async dispatch => {
        firebaseActions.auth
            .signOut()
            .then(() => {
                return dispatch({ type: Types.LOGGED_USER, payload: null });
            });
    };
}

export async function startLogin() {
    firebaseActions.provider.setCustomParameters({ prompt: 'select_account' });
    await firebaseActions.auth.signInWithPopup(firebaseActions.provider);
    return async dispatch => {
        dispatch({ type: 'FIREBASE_LOGIN', loginAttempted: false });
        return dispatch({ type: 'FIREBASE_LOGIN' });
    };
}
