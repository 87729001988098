import { combineReducers } from 'redux';
import graphData from './graphData';
import applicationSettings from './applicationSettings';
import programData from './programData';
import loggedUser from './loggedUser';
import productivityData from './productivityData';
import metrics from './metrics';
import jobs from './jobs';
import podStats from './podStatsReducer';

const rootReducer = combineReducers({
    graphData,
    applicationSettings,
    programData,
    loggedUser,
    productivityData,
    metrics,
    jobs,
    podStats
});

export default rootReducer;
