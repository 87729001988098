import compact from 'lodash/compact';
import { createArray } from '../../higherOrderFunctions';
import formatToFusionChart from '../fusionChartsFormatters';

const transposeToDaily = async groupedData => {
    const rangeData = {};

    groupedData.forEach((dailyData, dayIndex) => {
        const { dayKey, customFieldsData } = dailyData;
        Object.keys(customFieldsData).forEach(customField => {
            const customFieldData = customFieldsData[customField];
            Object.keys(customFieldData).forEach(fieldKey => {
                if (fieldKey === 'dataLine') return false;

                let fieldValue = JSON.parse(JSON.stringify(customFieldData[fieldKey]));
                if (Array.isArray(fieldValue)) {
                    const placeHolder = fieldValue.slice(-1);
                    const newValue = placeHolder[0];
                    fieldValue = newValue;
                }

                if (
                    rangeData[customField] &&
                    typeof rangeData[customField][fieldKey] !== 'undefined'
                ) {
                    // filling gaps in-between data points
                    if (
                        rangeData[customField][fieldKey].length <
                        rangeData[customField].dayLine.length
                    )
                        rangeData[customField][fieldKey] = rangeData[customField][fieldKey].concat(
                            createArray(
                                rangeData[customField].dayLine.length -
                                    rangeData[customField][fieldKey].length,
                                null
                            )
                        );
                    rangeData[customField][fieldKey].push(fieldValue);
                } else if (typeof rangeData[customField] !== 'undefined') {
                    // filling the first section of the array with the amount of days this serie was missing
                    if (rangeData[customField].dayLine.length > 1 || dayIndex > 0)
                        rangeData[customField][fieldKey] = createArray(
                            rangeData[customField].dayLine.length,
                            null
                        ).concat([fieldValue]);
                    else rangeData[customField][fieldKey] = [fieldValue];
                } else {
                    rangeData[customField] = {
                        [fieldKey]: [fieldValue],
                        dayLine: [dayKey.replace(/_/g, '/').slice(0, 5)]
                    };
                }
                return true;
            });
            if (
                rangeData[customField].dayLine.indexOf(dayKey.replace(/_/g, '/').slice(0, 5)) === -1
            )
                rangeData[customField].dayLine.push(dayKey.replace(/_/g, '/').slice(0, 5));
        });
    });
    return rangeData;
};

const formatAllData = async groupedData => {
    if (!groupedData) return null;

    return groupedData.map(dataByDate => {
        const { customFieldsData, dayKey } = dataByDate;
        const formattedCustomFieldsData = {};
        Object.keys(customFieldsData).forEach(customFieldDataKey => {
            const placeHolder = formatToFusionChart(
                [customFieldsData[customFieldDataKey]],
                'complex'
            );
            const newCustomFields = placeHolder[0];
            formattedCustomFieldsData[customFieldDataKey] = newCustomFields;

            formattedCustomFieldsData[customFieldDataKey].dayKey = dayKey;
        });
        return {
            customFieldsData: formattedCustomFieldsData,
            dayKey
        };
    });
};

const formatRangeData = async groupedData => {
    const rangeData = await transposeToDaily(groupedData);
    const wrappedData = [{ customFieldsData: {} }]; // just to follow the same structure as live

    Object.keys(rangeData).forEach(customFieldKey => {
        const customField = rangeData[customFieldKey];

        const labels = customField.dayLine.map(day => {
            return { label: day };
        });
        const categories = [{ category: labels }];

        const dataset = compact(
            Object.keys(customField).map(customFieldDataKey => {
                if (customFieldDataKey === 'dayLine') return false;
                return {
                    seriesname: customFieldDataKey,
                    data: customField[customFieldDataKey].map(tagData => {
                        return { value: tagData };
                    })
                };
            })
        );

        const customFieldData = {
            categories,
            dataset
        };
        wrappedData[0].customFieldsData[customFieldKey] = customFieldData;
        wrappedData[0].dayKey = groupedData[0].dayKey;
    });
    return wrappedData;
};

const getHourlyData = async (dayData, hours, dayKey) => {
    const customFieldsData = {};

    hours.forEach(hour => {
        const index = dayData[hour];

        Object.keys(index).forEach(objKey => {
            if (index[objKey].type === 'customFields') {
                const hourlyCustomFields = index[objKey];

                const customFields = Object.keys(hourlyCustomFields);
                customFields.forEach(field => {
                    if (field === 'type') return false;
                    const previousHours = customFieldsData[field]
                        ? customFieldsData[field].dataLine
                        : 0;
                    const fieldsKeysArray = hourlyCustomFields[field];
                    Object.keys(fieldsKeysArray).forEach(fieldKey => {
                        // filling gaps in-between hours of an specific series
                        if (
                            customFieldsData[field] &&
                            typeof customFieldsData[field].series !== 'undefined' &&
                            typeof customFieldsData[field].series[fieldKey] !== 'undefined' &&
                            previousHours.length > customFieldsData[field].series[fieldKey].length
                        ) {
                            const amountOfSpaces =
                                previousHours.length -
                                customFieldsData[field].series[fieldKey].length;
                            customFieldsData[field].series[fieldKey] = customFieldsData[
                                field
                            ].series[fieldKey].concat(createArray(amountOfSpaces, null));
                            customFieldsData[field].series[fieldKey].push(
                                fieldsKeysArray[fieldKey]
                            );
                        }
                        // filling the serie with its hourly value
                        else if (
                            customFieldsData[field] &&
                            typeof customFieldsData[field].series !== 'undefined' &&
                            typeof customFieldsData[field].series[fieldKey] !== 'undefined'
                        )
                            customFieldsData[field].series[fieldKey].push(
                                fieldsKeysArray[fieldKey]
                            );
                        // filling the serie's first portion with empty values in case of the presence of values before this hour
                        else if (
                            customFieldsData[field] &&
                            customFieldsData[field].series &&
                            !customFieldsData[field].series[fieldKey] &&
                            previousHours.length > 0
                        )
                            customFieldsData[field].series[fieldKey] = createArray(
                                previousHours.length,
                                null
                            ).concat([fieldsKeysArray[fieldKey]]);
                        // initializing this serie's value
                        else if (typeof customFieldsData[field] !== 'undefined')
                            customFieldsData[field].series = {
                                [fieldKey]: [fieldsKeysArray[fieldKey]]
                            };
                        // initializing this table's main obj
                        else
                            customFieldsData[field] = {
                                series: { [fieldKey]: [fieldsKeysArray[fieldKey]] }
                            };
                    });

                    if (customFieldsData[field].dataLine)
                        customFieldsData[field].dataLine.push(hour);
                    else customFieldsData[field].dataLine = [hour];

                    return false;
                });
            }
            return true;
        });
    });

    if (Object.keys(customFieldsData).length === 0) return false;

    return {
        dayKey,
        customFieldsData
    };
};

const buildDayObject = async (dayKey, dayData, displayMode) => {
    if (displayMode === 'range' && typeof dayData.customFields !== 'undefined') {
        const customFieldsData = JSON.parse(JSON.stringify(dayData.customFields));
        delete customFieldsData.type;
        return { dayKey, customFieldsData };
    }

    if (!dayData) return null;

    const hours = Object.keys(dayData.byHour);
    const dayObject = await getHourlyData(dayData.byHour, hours, dayKey);

    return dayObject;
};

const processAllDays = async (productivityData, displayMode, startDate, endDate) => {
    const days = Object.keys(productivityData).filter(day => day >= startDate && day <= endDate);
    const groupedData = [];

    await Promise.all(
        days.map(async dayKey => {
            const dayData = productivityData[dayKey];
            const formattedDayData = await buildDayObject(dayKey, dayData, displayMode);
            groupedData.push(formattedDayData);
        })
    );
    return compact(groupedData);
};

const formatChartData = async (productivityData, displayMode, startDate, endDate) => {
    if (!productivityData) return null;
    let filteredProductivityData = JSON.parse(JSON.stringify(productivityData));

    if (displayMode === 'range') {
        const rangedData = {};
        Object.keys(filteredProductivityData).forEach(date => {
            rangedData[date] = filteredProductivityData[date];
        });
        filteredProductivityData = rangedData;
    }

    const groupedData = await processAllDays(
        filteredProductivityData,
        displayMode,
        startDate,
        endDate
    );
    if (groupedData.length === 0) return null;
    let formattedData;

    if (displayMode === 'range') {
        formattedData = await formatRangeData(groupedData);
    } else formattedData = await formatAllData(groupedData);
    if (Object.keys(formattedData[0].customFieldsData).length === 0) {
        return null;
    }

    return formattedData;
};

export default formatChartData;
