/* eslint-disable no-param-reassign */
import produce from 'immer';
import moment from 'moment-timezone';

export const ORDERBY = {
    ASCENDING: 'ASCENDING',
    DESCENDING: 'DESCENDING'
};

export const SORTBY = {
    NAME: 'NAME'
};

const initialState = {
    displayMode: 'live',
    startDate: moment().format('MM_DD_YY'),
    endDate: moment().format('MM_DD_YY'),
    pods: [],
    sortBy: SORTBY.NAME,
    orderBy: ORDERBY.ASCENDING,
    podParity: false
};

export default (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case 'PODS_ORDERBY':
                draft.orderBy = action.payload;
                break;
            case 'PODS_SORTBY':
                draft.sortBy = action.payload;
                break;
            case 'CLEAR_POD_DATA':
                draft.pods = [];
                draft.podParity = false;
                break;
            case 'POD_HOURLY_THROUGHPUT_PENDING':
                draft.pods.push({
                    id: action.payload.id,
                    loading: true,
                    error: null,
                    data: null,
                    logo: '',
                    program: null,
                    active: null
                });
                break;
            case 'POD_HOURLY_THROUGHPUT_FULFILLED':
                draft.pods = state.pods.filter(pod => pod.id !== action.payload.id);
                draft.pods.push({
                    id: action.payload.id,
                    loading: false,
                    error: null,
                    data: action.payload.data,
                    logo: action.payload.logo,
                    program: action.payload.program,
                    active: action.payload.program.settings.active
                });
                draft.podParity = true;
                break;
            case 'POD_HOURLY_THROUGHPUT_REJECTED':
                // draft.data[action.payload.id].data = null;
                // draft.data[action.payload.id].fetching = false;
                break;
            case 'SET_PRODUCTIVITY_FILTERS':
                draft.startDate = action.payload.startDate;
                draft.endDate = action.payload.endDate;
                draft.displayMode = action.payload.displayMode;
                draft.podParity = false;
                break;
            case 'SET_DISPLAY_MODE':
                draft.displayMode = action.payload.displayMode;
                break;
            case 'SET_START_DATE':
                draft.startDate = action.payload.startDate;
                draft.podParity = false;
                break;
            case 'SET_END_DATE':
                draft.endDate = action.payload.endDate;
                draft.podParity = false;
                break;
            case 'SET_BOTH_DATES':
                draft.startDate = action.payload.startDate;
                draft.endDate = action.payload.endDate;
                break;
            default:
        }
    });
};
