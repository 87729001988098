import _compact from 'lodash/compact';

export default grouppedData => {
    const formattedData = [];
    grouppedData.forEach(dailyData => {
        const { dataLine, series } = dailyData;

        if (dataLine || dataLine.length !== 0 || Object.keys(series).length !== 0) {
            const category = dataLine.map(value => {
                return {
                    label: value
                };
            });
            let counter = -1;
            const increment = () => {
                counter += 1;
                return counter;
            };
            const dataset = Object.keys(series).map(serieName => {
                const i = increment();

                const colors = [
                    '#ffffff',
                    '#ff9e00',
                    '#90C695',
                    '#05583e',
                    '#f47d54',
                    '#7d6cb7',
                    '#104c6d',
                    '#e08283',
                    '#fba969',
                    '#50bccd',
                    '#cc6050'
                ];
                return {
                    seriesname: serieName,
                    anchorBgColor: colors[i],
                    data: series[serieName].map(value => {
                        let valueToBeDisplayed = value;

                        // value can be -1 depending on ZD's responses
                        // meaning that there is no data for this specific
                        // spot and therefore should be hidden within the rendered
                        // chart
                        if (valueToBeDisplayed === -1) valueToBeDisplayed = null;

                        return { value: valueToBeDisplayed };
                    })
                };
            });

            const dataSource = {
                categories: [{ category }],
                dataset
            };

            const returnObject = {
                ...dataSource,
                dayKey: dailyData.dayKey
            };

            formattedData.push(returnObject);
        }
    });

    return _compact(formattedData);
};
