import moment from 'moment-timezone';
import produce from 'immer';

const initialState = {
    startDate: moment().format('MM_DD_YY'),
    globalProgramId: null,
    globalProgram: null,
    fetchingGlobalProgram: false,
    productivityData: null,
    isRefresh: null,
    jobs: null,
    displayMode: 'live',
    lazy: false,
    fetchingGroups: false,
    fetchingTicketFields: false,
    fetchingViews: false,
    updatingSettings: false,
    updatingMetrics: false,
    updatingViews: false,
    removingUser: false,
    updatingUser: false,
    creatingUser: false,
    creatingExternalUser: false,
    promotingUser: false,
    removingAdmin: false,
    deletingPod: false,
    groups: [],
    ticketFields: [],
    views: [],
    error: ''
};

export default (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case 'FETCH_GLOBAL_PROGRAM_PENDING':
                draft.fetchingGlobalProgram = true;
                break;
            case 'FETCH_GLOBAL_PROGRAM_FULFILLED':
                draft.globalProgram = action.payload.globalProgram;
                draft.fetchingGlobalProgram = false;
                break;
            case 'FETCH_GLOBAL_PROGRAM_REJECTED':
                draft.globalProgram = null;
                draft.fetchingGlobalProgram = false;
                draft.error = action.payload.error;
                break;
            case 'FETCH_TICKET_FIELDS_PENDING':
                draft.fetchingTicketFields = true;
                draft.ticketFields = ['Loading...'];
                break;
            case 'FETCH_TICKET_FIELDS_FULFILLED':
                draft.ticketFields = [...new Set(action.payload)];
                break;
            case 'FETCH_TICKET_FIELDS_REJECTED':
                draft.ticketFields = [];
                break;
            case 'FETCH_VIEWS_PENDING':
                draft.fetchingViews = true;
                draft.views = ['Loading...'];
                break;
            case 'FETCH_VIEWS_FULFILLED':
                draft.views = [...new Set(action.payload)];
                break;
            case 'FETCH_VIEWS_REJECTED':
                draft.views = [];
                break;
            case 'UPDATE_SETTINGS_PENDING':
                draft.updatingSettings = true;
                draft.error = '';
                break;
            case 'UPDATE_SETTINGS_FULFILLED':
                draft.updatingSettings = false;
                draft.globalProgram.settings = action.payload;
                break;
            case 'UPDATE_SETTINGS_REJECTED':
                draft.updatingSettings = false;
                draft.error = 'Error updating settings.';
                break;
            case 'UPDATE_VIEWS_PENDING':
                draft.updatingViews = true;
                draft.error = '';
                break;
            case 'UPDATE_VIEWS_FULFILLED':
                draft.updatingViews = false;
                draft.globalProgram.views = action.payload;
                break;
            case 'UPDATE_VIEWS_REJECTED':
                draft.updatingViews = false;
                draft.error = 'Error updating views.';
                break;
            case 'REMOVE_TEAM_MEMBER_PENDING':
            case 'REMOVE_EXTERNAL_PENDING':
                draft.removingUser = true;
                draft.error = '';
                break;
            case 'REMOVE_TEAM_MEMBER_FULFILLED': {
                const clone = { ...state.globalProgram.team };
                delete clone[action.payload];
                draft.removingUser = false;
                draft.globalProgram.team = clone;
                break;
            }
            case 'REMOVE_TEAM_MEMBER_REJECTED':
                draft.removingUser = false;
                draft.error = 'Could not remove team member';
                break;
            case 'UPDATE_TEAM_MEMBER_PENDING':
                draft.updatingUser = true;
                draft.error = '';
                break;
            case 'UPDATE_TEAM_MEMBER_FULFILLED': {
                const clone = { ...state.globalProgram.team };
                clone[action.payload.id] = action.payload;
                draft.updatingUser = false;
                draft.globalProgram.team = clone;
                break;
            }
            case 'UPDATE_TEAM_MEMBER_REJECTED':
                draft.updatingUser = false;
                draft.error = 'Could not update team member';
                break;
            case 'REMOVE_EXTERNAL_FULFILLED': {
                draft.removingUser = false;
                draft.globalProgram.externalUsers = state.globalProgram.externalUsers.filter(
                    u => u.email !== action.payload.email
                );
                break;
            }
            case 'REMOVE_EXTERNAL_REJECTED':
                draft.removingUser = false;
                draft.error = 'Could not remove external user';
                break;
            case 'CREATE_TEAM_MEMBER_PENDING':
                draft.creatingUser = true;
                draft.error = '';
                break;
            case 'CREATE_TEAM_MEMBER_FULFILLED': {
                draft.creatingUser = false;
                draft.globalProgram.team[action.payload.id] = action.payload;
                break;
            }
            case 'CREATE_TEAM_MEMBER_REJECTED':
                draft.creatingUser = false;
                draft.error = 'Could not add user';
                break;
            case 'CREATE_NEW_POD_REJECTED':
                draft.creatingPod = false;
                draft.error = 'Could not create new pod';
                break;
            case 'CREATE_EXTERNAL_USER_PENDING':
                draft.creatingExternalUser = true;
                draft.newExternalUser = action.payload;
                break;
            case 'CREATE_EXTERNAL_USER_FULFILLED':
                draft.creatingExternalUser = false;
                draft.newExternalUser = action.payload;
                break;
            case 'CREATE_EXTERNAL_USER_FAILURE':
                draft.creatingExternalUser = false;
                draft.error = action.payload;
                break;
            case 'REMOVE_USER_FROM_POD_FULFILLED':
                draft.globalProgram.team = action.payload;
                break;
            case 'PROMOTE_USER_TO_ADMIN_PENDING':
                draft.promotingUser = true;
                draft.error = '';
                break;
            case 'PROMOTE_USER_TO_ADMIN_FULFILLED': {
                draft.promotingUser = false;
                break;
            }
            case 'PROMOTE_USER_TO_ADMIN_REJECTED':
                draft.promotingUser = false;
                draft.error = 'Could not promote user to admin';
                break;
            case 'REMOVE_ADMIN_PENDING':
                draft.removingAdmin = true;
                draft.error = '';
                break;
            case 'REMOVE_ADMIN_FULFILLED': {
                draft.removingAdmin = false;
                break;
            }
            case 'REMOVE_ADMIN_REJECTED':
                draft.removingAdmin = false;
                draft.error = 'Could not remove user from admins';
                break;
            case 'DELETE_POD_PENDING':
                draft.deletingPod = true;
                draft.error = '';
                break;
            case 'DELETE_POD_FULFILLED': {
                draft.deletingPod = false;
                break;
            }
            case 'DELETE_POD_REJECTED':
                draft.deletingPod = false;
                draft.error = 'Could not delete pod.';
                break;
            case 'CREATE_EXTERNAL_USER_REJECTED':
                draft.creatingUser = false;
                draft.error = 'Could not add external user';
                break;
            default:
                return state;
        }
    });
};
