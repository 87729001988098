import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { startLogin, login } from 'Actions';
import { useSnackbar } from 'notistack';
import Loader from 'Components/Loader';
import * as firebaseActions from '../../actions/actionsFirebase';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'primary',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        display: 'grid',
        justifyContent: 'center',
        textAlign: 'center'
    },
    box: {
        border: '1px solid rgb(64, 63, 63)',
        backgroundColor: theme.palette.primary[900],
        borderRadius: '18px'
    }
}));

const Login = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { loginAttempted } = useSelector(state => state.loggedUser);
    const [isAuthenticating, SetIsAuthenticating] = useState(false);

    const firebaseCheck = () =>
        new Promise(resolve =>
            firebaseActions.FB.auth().onAuthStateChanged(async user => {
                if (user) {
                    SetIsAuthenticating(true);
                    const userRes = await dispatch(login(user));
                    resolve(userRes);
                } else {
                    resolve({});
                }
            })
        );

    useEffect(() => {
        if (loginAttempted) {
            firebaseCheck().then(response => {
                SetIsAuthenticating(false);
                if (!response.payload) {
                    enqueueSnackbar('Login Failed! Please login with a valid account.', {
                        variant: 'error',
                        autoHideDuration: 3000,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        }
                    });
                }
            });
        }
    }, [loginAttempted]);

    return isAuthenticating ? (
        <Loader id="auth__loader" fullScreen iconSize="large" message="Authenticating..."></Loader>
    ) : (
        <div style={{ maxWidth: '1400px', maxHeight: '100%' }}>
            <div className={classes.root}>
                <div className={classes.box}>
                    <Grid
                        container
                        alignItems="center"
                        direction="row"
                        style={{ height: '400px', paddingBottom: '15px' }}
                    >
                        <Grid item xs={12}>
                            <img
                                src="../assets/logos/beacon_logo.png"
                                alt="Beacon"
                                style={{
                                    width: '200px',
                                    opacity: '0.6'
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <span
                                style={{
                                    textAlign: 'center',
                                    width: '60%',
                                    transform: 'translate(-50%, -50%)'
                                }}
                            >
                                <Typography
                                    id="login__title"
                                    variant="h5"
                                    style={{ color: 'primary' }}
                                >
                                    Welcome to Beacon
                                </Typography>
                                <Typography
                                    variant="body1"
                                    style={{ color: 'primary', paddingTop: '5px' }}
                                >
                                    A tool that helps high performance teams focus on the stats that
                                    matter.
                                </Typography>
                            </span>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                id="login__login-button"
                                variant="contained"
                                color="primary"
                                onClick={() => dispatch(startLogin())}
                            >
                                Login
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
};

Login.propTypes = {};

export default Login;
