/**
 * Authorization Roles
 */
const authRoles = {
    euler: ['euler'],
    admin: ['euler', 'admin'],
    user: ['euler', 'admin', 'user'],
    noAccess: ['euler', 'admin', 'user', 'no_access'],
    guest: []
    // external: ['user'] // probably not going to use it
};

export default authRoles;
