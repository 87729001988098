import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { version } from '../package.json';
import history from './history';
import store from './store';

import App from './app';

// eslint-disable-next-line no-restricted-globals
if (process.env.NODE_ENV === 'production' && !location.pathname.includes('staging')) {
    Sentry.init({
        dsn: process.env.SENTRY_URL,
        release: version,
        environment: process.env.NODE_ENV
    });

    Sentry.configureScope(scope => {
        scope.setTag('project', 'frontend');
    });
}

ReactDOM.render(<App store={store} history={history} />, document.getElementById('app'));

if (module.hot) {
    module.hot.accept('./app', () => {
        ReactDOM.render(<App store={store} history={history} />, document.getElementById('app'));
    });
}
