import _compact from 'lodash/compact';
import { camelCaseToTitle } from '../../higherOrderFunctions';
import formatToFusionChart from '../fusionChartsFormatters';

const groupRangedData = (dailyMetricsData, metricsNames) => {
    return _compact(
        Object.keys(dailyMetricsData).map(dayKey => {
            let series = {};

            metricsNames.forEach(camelCasedTitle => {
                let title = camelCaseToTitle(camelCasedTitle);
                if (title.length > 16 && title.includes('Time')) {
                    const placeHolder = title.split(' Time');
                    const newValue = placeHolder[0];
                    title = newValue;
                }

                if (typeof series[title] === 'undefined') series = { ...series, [title]: {} };
                if (typeof dailyMetricsData[dayKey].teamAverage !== 'undefined')
                    series[title] = dailyMetricsData[dayKey].teamAverage[camelCasedTitle].calendar;
            });

            return {
                dayKey: dayKey.replace(/_/g, '/').slice(0, 5),
                originalDayKey: dayKey,
                series
            };
        })
    );
};

const getHourlySeries = (dayData, hours, dayKey, metricsNames, timeType) => {
    let timeLines = {};

    hours.forEach(hour => {
        const index = dayData[hour].byUser;

        const { teamAverage } = index;

        metricsNames.forEach(camelCasedTitle => {
            const metricTitle = camelCaseToTitle(camelCasedTitle);
            if (typeof timeLines[metricTitle] === 'undefined')
                timeLines = { ...timeLines, [metricTitle]: [] };

            if (typeof teamAverage[camelCasedTitle] === 'undefined')
                timeLines[metricTitle].push(null);
            else
                timeLines[metricTitle].push(
                    parseFloat(teamAverage[camelCasedTitle][timeType]).toFixed(1)
                );
        });
    });

    return {
        dayKey,
        dataLine: hours,
        series: timeLines
    };
};

const buildDayObject = (dayKey, dayData, metricsNames, timeType) => {
    if (!dayData || (dayData && !dayData.byHour)) return null;

    const hours = Object.keys(dayData.byHour);
    return getHourlySeries(dayData.byHour, hours, dayKey, metricsNames, timeType);
};

const processAllDays = (productivityData, displayMode, metricsNames, timeType) => {
    const days = Object.keys(productivityData);
    let groupedData = [];

    if (displayMode === 'range') {
        if (!productivityData) return [];

        groupedData = groupRangedData(productivityData, metricsNames);
    } else
        days.forEach(dayKey => {
            const dayData = productivityData[dayKey];
            const formatteddayData = buildDayObject(dayKey, dayData, metricsNames, timeType);
            groupedData.push(formatteddayData);
        });
    return _compact(groupedData);
};

const groupAllData = (productivityData, displayMode, metricsNames, timeType) => {
    return processAllDays(productivityData, displayMode, metricsNames, timeType);
};

const formatRangeData = groupedData => {
    const dataLine = [];
    const series = {};

    groupedData.forEach(dataByDate => {
        dataLine.push(dataByDate.dayKey);
        Object.keys(dataByDate.series).forEach(serieName => {
            const currentSerieValue = dataByDate.series[serieName];
            if (typeof series[serieName] === 'undefined') series[serieName] = [currentSerieValue];
            else series[serieName].push(currentSerieValue);
        });
    });

    return {
        dayKey: groupedData[0].originalDayKey,
        dataLine,
        series
    };
};

const formatChartData = async (programData, originalMetricsData, displayMode, metricsNames) => {
    const metricsData = originalMetricsData;

    if (!metricsData) return null;
    let filteredProductivityData = JSON.parse(JSON.stringify(metricsData));

    const { startDate, endDate, globalProgram } = programData;

    if (!globalProgram) return null;

    const { settings } = globalProgram;
    const timeType = settings.timeType || 'calendar';

    if (displayMode === 'range') {
        const rangedData = {};
        let startFiltering = false;
        Object.keys(filteredProductivityData).forEach(date => {
            if (date === startDate) startFiltering = true;
            if (startFiltering) rangedData[date] = filteredProductivityData[date];
            if (date === endDate) startFiltering = false;
        });
        filteredProductivityData = rangedData;
    }

    const groupedData = groupAllData(filteredProductivityData, displayMode, metricsNames, timeType);

    let formattedData;

    if (displayMode === 'range') {
        const rangedData = formatRangeData(groupedData);
        formattedData = formatToFusionChart([rangedData], 'complex');
    } else formattedData = formatToFusionChart(groupedData, 'complex');

    return formattedData;
};

export default { formatChartData };
