import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import ThemeContext from '../../ThemeContext';

const useStyles = makeStyles(theme => ({
    loaderWrapper: {
        minWidth: '100%',
        minHeight: '100%',
        background: theme.palette.background.default,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    fullScreen: {
        minWidth: '100vw',
        minHeight: '100vh'
    },
    small: {
        width: '32px',
        height: '32px'
    },
    medium: {
        width: '64px',
        height: '64px'
    },
    large: {
        width: '180px',
        height: '180px',
        transform: 'rotate(270deg)'
    }
}));

const Loader = props => {
    const { type } = React.useContext(ThemeContext);
    const { fullScreen, iconSize, message } = props;
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.loaderWrapper, fullScreen && classes.fullScreen)}
            data-testid="loader_test_div"
        >
            <img
                className={clsx(classes[iconSize])}
                src={
                    type === 'light'
                        ? '../../assets/svgs/loading.svg'
                        : '../../assets/svgs/loading-dark-2.svg'
                }
                alt="Loading..."
                data-testid="loader_test_image"
            />
            {message && (
                <Typography component="div" data-testid="loader_test_typography">
                    <Box>{message}</Box>
                </Typography>
            )}
        </div>
    );
};

Loader.propTypes = {
    iconSize: PropTypes.oneOf(['small', 'medium', 'large']),
    fullScreen: PropTypes.bool,
    message: PropTypes.string
};

Loader.defaultProps = {
    iconSize: 'small',
    fullScreen: false,
    message: null
};

export default Loader;
