import React from 'react';
import { useLocation } from 'react-router-dom';
import { analytics } from './actions/actionsFirebase';

const FirebaseAnalytics = () => {
    const location = useLocation();
    React.useEffect(() => {
        if (analytics) {
            const pagePath = location.pathname + location.search;
            analytics.setCurrentScreen(pagePath);
            analytics.logEvent('page_view', { pagePath });
        }
    }, [location]);
    return null;
};

export default FirebaseAnalytics;
