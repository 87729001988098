/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-globals */
import _filter from 'lodash/filter';
import _sumBy from 'lodash/sumBy';
import _clone from 'lodash/clone';
import formatToFusionChart from '../fusionChartsFormatters';

const getProductionTotal = (formattedData, goalType) => {
    if (formattedData.length <= 0)
        return {
            compliance: 0
        };
    const { dataset } = formattedData[0];
    const productionType = goalType.replace(/^\w/, c => c.toUpperCase());

    // extracting data points to totalize
    const production = dataset.find(e => e.seriesname === productionType);
    const solved = dataset.find(e => e.seriesname === 'Solved');
    const touches = dataset.find(e => e.seriesname === 'Touches');
    const goal = dataset.find(e => e.seriesname === 'Goal');
    const open = dataset.find(e => e.seriesname === 'Open');
    const pending = dataset.find(e => e.seriesname === 'Pending');
    const newTickets = dataset.find(e => e.seriesname === 'NewTickets');
    // extract raw totals
    const goalTotal = goal ? goal.data.reduce((a, b) => a + b.value, 0) : 0;
    const openTotal = open ? open.data.reduce((a, b) => a + b.value, 0) : 0;
    const pendingTotal = pending ? pending.data.reduce((a, b) => a + b.value, 0) : 0;
    const solvedTotal = solved ? solved.data.reduce((a, b) => a + b.value, 0) : 0;

    const touchesTotal = touches ? touches.data.reduce((a, b) => a + b.value, 0) : 0;
    const productionTotal = production ? production.data.reduce((a, b) => a + b.value, 0) : 0;

    const currentDelta = production
        ? production.data[production.data.length - 1].value / goal.data[goal.data.length - 1].value
        : 0;
    // eslint-disable-next-line no-nested-ternary
    const lastDelta = production
        ? production.data.length >= 2
            ? production.data[production.data.length - 2].value /
              goal.data[goal.data.length - 2].value
            : 0
        : 0;
    const trend = { name: 'Trend', value: currentDelta - lastDelta };

    // TODO: this fix was added for range. check if we're not pulling
    // other ticket statuses correctly for range
    if (!open) return {};

    const formatTicketValue = val => `${val} tickets`;

    // formatted values
    const openFormatted = {
        name: 'Open',
        value: openTotal,
        pipeValue: formatTicketValue
    };
    const pendingFormatted = {
        name: 'Pending',
        value: pendingTotal,
        pipeValue: formatTicketValue
    };
    const solvedFormatted = {
        name: 'Solved',
        value: solvedTotal,
        pipeValue: formatTicketValue
    };
    const touchesFormatted = {
        name: 'Touches',
        value: touchesTotal,
        pipeValue: val => `${val} messages`
    };
    const goalFormatted = {
        name: 'Goal',
        value: goalTotal,
        pipeValue: val => {
            if (goalType === 'touches') return `${val} messages`;
            return `${val} tickets`;
        }
    };

    const createdFormatted = {
        name: 'Created',
        value:
            newTickets && newTickets.data.length > 0
                ? newTickets.data[newTickets.data.length - 1].value
                : 0,
        pipeValue: val => `${val} tickets`
    };

    const primaryStat = goalType === 'touches' ? touchesFormatted : solvedFormatted;
    const secondaryStat = goalType === 'touches' ? solvedFormatted : touchesFormatted;
    const performance = {
        name: 'Performance',
        value: goalTotal > 0 ? (productionTotal / goalTotal) * 100 : 100,
        pipeValue: val => `${val.toFixed(0)}%`
    };

    return {
        performance,
        trend,
        stats: [
            primaryStat,
            goalFormatted,
            secondaryStat,
            openFormatted,
            pendingFormatted,
            createdFormatted
        ]
    };
};

const getProductionDeltaTotal = (groupedData, goal, productionTotals, displayMode) => {
    if (!groupedData.length) return { compliance: 0 };
    if (groupedData.length > 1 || (groupedData.length === 1 && displayMode === 'range')) return {};
    const data = groupedData[0].deltas;

    const deltaSolved = data.solved.reduce((a, b) => a + b, 0);
    const deltaGoal = data.goal.reduce((a, b) => a + b, 0);
    const deltaTouches = data.touches.reduce((a, b) => a + b, 0);
    const deltaPending = data.pending.reduce((a, b) => a + b, 0);
    const deltaOpen = data.open.reduce((a, b) => a + b, 0);
    const deltaProd = data.production.reduce((a, b) => a + b, 0);

    const formatTicketValue = val => `${val} tickets`;

    const openFormatted = {
        name: 'Open',
        value: deltaOpen,
        pipeValue: formatTicketValue
    };
    const pendingFormatted = {
        name: 'Pending',
        value: deltaPending,
        pipeValue: formatTicketValue
    };
    const solvedFormatted = {
        name: 'Solved',
        value: deltaSolved,
        pipeValue: formatTicketValue
    };
    const touchesFormatted = {
        name: 'Touches',
        value: deltaTouches,
        pipeValue: val => `${val} messages`
    };
    const goalFormatted = {
        name: 'Goal',
        value: deltaGoal,
        pipeValue: val => {
            if (goal === 'touches') return `${val} messages`;
            return `${val} tickets`;
        }
    };

    const primaryStat = goal === 'touches' ? touchesFormatted : solvedFormatted;
    const secondaryStat = goal === 'touches' ? solvedFormatted : touchesFormatted;

    const performance = {
        name: 'Performance',
        value: deltaGoal > 0 ? (deltaProd / deltaGoal) * 100 : 100,
        pipeValue: val => `${val.toFixed(0)}%`
    };
    const createdFormatted = productionTotals.stats[productionTotals.stats.length - 1];

    return {
        performance,
        stats: [
            primaryStat,
            goalFormatted,
            secondaryStat,
            openFormatted,
            pendingFormatted,
            createdFormatted
        ],
        trend: productionTotals.trend
    };
};

const formatRangeData = (groupedData, startDate) => {
    const dataLine = [];
    const goalLine = [];
    const prodLine = [];

    groupedData.forEach(dateIndex => {
        if (dateIndex.originalDayKey >= startDate) {
            dataLine.push(dateIndex.dayKey);
            prodLine.push(dateIndex.production);
            goalLine.push(dateIndex.goal);
        }
    });

    return {
        dataLine,
        series: {
            Goal: goalLine,
            Production: prodLine
        },
        dayKey: startDate
    };
};

const filterProductivity = index =>
    _filter(index, o => o.email && o.objectType === 'userHourlyStatus');

const extractHourlySeries = (dayData, dayKey, goal) => {
    const dataLine = Object.keys(dayData);
    const goalLine = [];
    const productionLine = [];
    const openLine = [];
    const solvedLine = [];
    const pendingLine = [];
    const touchesLine = [];
    const newTicketsLine = [];
    const newSolvedTotal = [];
    const newGoalTotal = [];
    const newTouchesTotal = [];
    const newPendingTotal = [];
    const newOpenTotal = [];
    const newProdTotal = [];

    const evaluatedUsers = [];
    dataLine
        .slice()
        .reverse()
        .forEach(hour => {
            const index = dayData[hour];
            const filteredIndex = filterProductivity(index);

            const solvedPerHour = filteredIndex.reduce((total, val) => {
                if (!evaluatedUsers.includes(val.email)) total += Number(val.solved);
                return total;
            }, 0);

            const goalPerHour = filteredIndex.reduce((total, val) => {
                if (!evaluatedUsers.includes(val.email)) total += Number(val.goal);
                return total;
            }, 0);

            const touchesPerHour = filteredIndex.reduce((total, val) => {
                if (!evaluatedUsers.includes(val.email)) total += Number(val.touches);
                return total;
            }, 0);

            const pendingPerHour = filteredIndex.reduce((total, val) => {
                if (!evaluatedUsers.includes(val.email)) total += Number(val.pending);
                return total;
            }, 0);

            const openPerHour = filteredIndex.reduce((total, val) => {
                if (!evaluatedUsers.includes(val.email)) total += Number(val.open);
                return total;
            }, 0);

            const prodPerHour = filteredIndex.reduce((total, val) => {
                if (!evaluatedUsers.includes(val.email)) total += Number(val[goal]);
                evaluatedUsers.push(val.email);
                return total;
            }, 0);

            newGoalTotal.push(goalPerHour);
            newSolvedTotal.push(solvedPerHour);
            newTouchesTotal.push(touchesPerHour);
            newPendingTotal.push(pendingPerHour);
            newOpenTotal.push(openPerHour);
            newProdTotal.push(prodPerHour);
        });

    dataLine.forEach(hour => {
        const index = dayData[hour];
        const filteredIndex = filterProductivity(index);
        const totalIntervalGoal = _sumBy(filteredIndex, o => {
            return Number(o.goal);
        });
        const totalIntervalProd = _sumBy(filteredIndex, o => {
            return Number(o[goal]);
        });
        const totalIntervalOpen = _sumBy(filteredIndex, o => {
            return Number(o.open);
        });
        const totalIntervalSolved = _sumBy(filteredIndex, o => {
            return Number(o.solved);
        });
        const totalIntervalPending = _sumBy(filteredIndex, o => {
            return Number(o.pending);
        });
        const totalIntervalTouches = _sumBy(filteredIndex, o => {
            return Number(o.touches);
        });

        const value = Object.entries(index).find(val => val[1].type === 'queueLoad');

        const queueLoad = value[1];

        const NewTickets = 'New Tickets';

        const totalIntervalNewTickets =
            queueLoad && queueLoad[NewTickets] && !isNaN(queueLoad[NewTickets].daily)
                ? queueLoad[NewTickets].daily
                : 0;

        goalLine.push(totalIntervalGoal);
        productionLine.push(totalIntervalProd);
        openLine.push(totalIntervalOpen);
        solvedLine.push(totalIntervalSolved);
        pendingLine.push(totalIntervalPending);
        touchesLine.push(totalIntervalTouches);
        newTicketsLine.push(totalIntervalNewTickets);
    });

    return {
        dayKey,
        dataLine,
        series: {
            Goal: goalLine,
            Production: productionLine,
            Open: openLine,
            Solved: solvedLine,
            Pending: pendingLine,
            Touches: touchesLine,
            NewTickets: newTicketsLine
        },
        deltas: {
            goal: newGoalTotal,
            production: newProdTotal,
            open: newOpenTotal,
            solved: newSolvedTotal,
            pending: newPendingTotal,
            touches: newTouchesTotal
        }
    };
};

const buildDayObject = (dayKey, dayData, goal) => {
    if (!dayData || (dayData && !dayData.byHour)) return null;
    return extractHourlySeries(dayData.byHour, dayKey, goal);
};

const getRangeSeries = (productivityData, goal, startDate, endDate) => {
    if (!productivityData) return null;

    const days = Object.keys(productivityData).filter(day => day >= startDate && day <= endDate);

    return days.map(dayKey => {
        const dayData = productivityData[dayKey];
        const filteredIndex = filterProductivity(dayData);
        const sumProduction = _sumBy(filteredIndex, goal);
        const sumGoal = _sumBy(filteredIndex, 'goal');

        return {
            production: sumProduction,
            goal: sumGoal,
            dayKey: _clone(dayKey)
                .replace(/_/g, '/')
                .slice(0, 5),
            originalDayKey: dayKey
        };
    });
};

const processAllDays = (globalProgram, productivityData, displayMode, startDate, endDate) => {
    const { goal } = globalProgram.settings;
    const days = Object.keys(productivityData);

    if (displayMode === 'range') return getRangeSeries(productivityData, goal, startDate, endDate);

    return days
        .map(dayKey => buildDayObject(dayKey, productivityData[dayKey], goal))
        .filter(Boolean);
};

export default (programData, displayMode) => {
    const { productivityData } = programData;
    if (!productivityData) return null;
    const filteredProductivityData = { ...productivityData };

    const { globalProgram, startDate, endDate } = programData;
    const groupedData = processAllDays(
        globalProgram,
        filteredProductivityData,
        displayMode,
        startDate,
        endDate
    );
    let formattedData;

    if (displayMode === 'range') {
        const rangedData = formatRangeData(groupedData, startDate);
        formattedData = formatToFusionChart([rangedData], 'complex');
    } else formattedData = formatToFusionChart(groupedData, 'complex');

    const {
        settings: { goal }
    } = globalProgram;

    const productionTotals = getProductionTotal(formattedData, goal);
    const productionDeltaTotals = getProductionDeltaTotal(
        groupedData,
        goal,
        productionTotals,
        displayMode
    );

    // Keep only goal and production
    formattedData = formattedData.map(data => {
        const newData = { ...data };
        newData.dataset = newData.dataset.filter(
            set => set.seriesname === 'Goal' || set.seriesname === 'Production'
        );
        return newData;
    });

    return { formattedData, productionTotals, productionDeltaTotals };
};
