import Types from '../actions/types';

const initialState = {
    email: '',
    euler: false,
    firstName: '',
    lastLogin: '',
    lastName: '',
    profilePicture: '',
    program: '',
    programs: {},
    role: [],
    rootName: '',
    loginAttempted: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.LOGGED_USER:
            if (action.payload === null) return initialState; // logout or not logged in
            return {
                ...state,
                ...action.payload
            };
        case Types.UPDATE_USER:
            return {
                ...state,
                ...action.payload
            };
        case 'FETCH_GLOBAL_PROGRAM_FULFILLED': {
            const { id } = action.payload.globalProgram.settings;
            let newRole = '';
            const foundProgram = state.allPrograms.find(program => program.programId === id);
            if (foundProgram) {
                newRole = foundProgram.role;
            } else {
                newRole = 'no_access';
            }
            return {
                ...state,
                role: state.euler ? 'euler' : newRole
            };
        }
        case 'FIREBASE_LOGIN': {
            return { ...state, loginAttempted: true };
        }
        default:
            return { ...state };
    }
};
