import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SnackbarProvider } from 'notistack';
import { Router } from 'react-router-dom';
import theme from 'Themes';
import FusionCharts from 'fusioncharts';
import ProgramRedirect from 'Components/ProgramRedirect';
import themes from './components/graphs/themes';
import Layout from './components/Layout/Layout';
import OnFocus from './components/OnFocus';

import ThemeContext from './ThemeContext';

import { Auth, Authorization } from './auth';

import FirebaseAnalytics from './firebaseAnalytics';

FusionCharts.register('theme', themes);
FusionCharts.options.creditLabel = false;

const App = ({ store, history }) => {
    const { type } = React.useContext(ThemeContext);

    return (
        <MuiThemeProvider theme={theme(type)}>
            <CssBaseline />
            <SnackbarProvider
                maxSnack={5}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
            >
                <Provider store={store}>
                    <Auth>
                        <Router history={history}>
                            <FirebaseAnalytics />
                            <ProgramRedirect>
                                <OnFocus />
                                <Authorization>
                                    <Layout />
                                </Authorization>
                            </ProgramRedirect>
                        </Router>
                    </Auth>
                </Provider>
            </SnackbarProvider>
        </MuiThemeProvider>
    );
};

App.propTypes = {
    store: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({}).isRequired
};

const ThemeWrapper = props => {
    const [type, setType] = React.useState('dark');
    const [collapsed, setCollapse] = React.useState(false);
    const [mobileSidebarOpen, setMobileSidebarOpen] = React.useState(false);
    const [title, setTitle] = React.useState('Beacon');

    const mdTheme = useTheme();
    const isDesktopOrBigger = useMediaQuery(mdTheme.breakpoints.up('md'));
    const isSmallerThanDesktop = useMediaQuery(mdTheme.breakpoints.down('md'));

    useEffect(() => {
        if (isSmallerThanDesktop) {
            setCollapse(true);
        }
    }, [isSmallerThanDesktop]);

    useEffect(() => {
        if (isDesktopOrBigger) {
            setCollapse(false);
        }
    }, [isDesktopOrBigger]);

    return (
        <ThemeContext.Provider
            value={{
                type,
                setType: newType => setType(newType),
                collapsed,
                setCollapsed: newCollapsed => setCollapse(newCollapsed),
                mobileSidebarOpen,
                setMobileSidebarOpen: newMobile => setMobileSidebarOpen(newMobile),
                title,
                setTitle: newTitle => setTitle(newTitle)
            }}
        >
            <App {...props} />
        </ThemeContext.Provider>
    );
};

export default ThemeWrapper;
