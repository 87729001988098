import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const NotFoundPage = () => {
    const history = useHistory();
    const user = useSelector(state => state.loggedUser);
    const redirectedProgram = user && user.program;

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            data-testid="LoggedInNotFoundPage_Container"
        >
            <Grid item xs={12} data-testid="LoggedInNotFoundPage_Image_Container">
                <img
                    data-testid="LoggedInNotFoundPage_Image"
                    src="../assets/svgs/dead_link.png"
                    style={{ display: 'inline-block', width: '22em', marginTop: '40px' }}
                    alt="404 not found"
                />
            </Grid>
            <Grid item data-testid="LoggedInNotFoundPage_Description_Container">
                <span
                    style={{
                        fontSize: '39px',
                        fontWeight: '130',
                        color: 'white'
                    }}
                >
                    {user
                        ? `I'm sorry ${user.firstName}, looks like you found a dead Link`
                        : "I'm sorry, looks like you found a dead Link"}
                </span>
            </Grid>
            <Grid item data-testid="LoggedInNotFoundPage_Actions_Container">
                <div style={{ marginTop: '40px' }}>
                    <Button
                        data-testid="LoggedInNotFoundPage_Go_Home_Buttton"
                        variant="contained"
                        color="secondary"
                        onClick={() =>
                            history.push(`/program/${redirectedProgram}/livestats/general`)
                        }
                    >
                        Go Home
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
};

export default NotFoundPage;
