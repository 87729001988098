import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        padding: theme.spacing(3),
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    topbar: {
        paddingBottom: theme.spacing(2)
    },
    footer: {
        paddingTop: theme.spacing(2)
    }
}));

const ViewWrapper = props => {
    const classes = useStyles();
    const { topBar, footer, children } = props;

    return (
        <div
            cy-test="viewWrapperContainer"
            data-testid="viewWrapper__container"
            className={classes.container}
        >
            {topBar && (
                <div data-testid="viewWrapper__topbar" className={classes.topbar}>
                    {topBar}
                </div>
            )}
            <div
                cy-test="viewWrapper"
                data-testid="viewWrapper__content"
                className={classes.content}
            >
                {children}
            </div>
            {footer && (
                <div data-testid="viewWrapper__footer" className={classes.footer}>
                    {footer}
                </div>
            )}
        </div>
    );
};

ViewWrapper.propTypes = {
    topBar: PropTypes.node,
    footer: PropTypes.node,
    children: PropTypes.node.isRequired
};

ViewWrapper.defaultProps = {
    topBar: null,
    footer: null
};

export default ViewWrapper;
