import { createMuiTheme } from '@material-ui/core';

const primary = {
    50: '#FBFBFB',
    100: '#F6F6F6',
    200: '#F1F1F1',
    300: '#E6E6E6',
    400: '#C3C3C3',
    500: '#A5A5A5',
    600: '#7C7C7C',
    700: '#676767',
    800: '#484848',
    900: '#262626'
};

function getTheme(theme) {
    if (theme.paletteType === 'dark')
        return createMuiTheme({
            props: {
                MuiCardHeader: {
                    titleTypographyProps: {
                        variant: 'h6'
                    },
                    subheaderTypographyProps: {
                        variant: 'body2'
                    }
                }
            },
            palette: {
                type: 'dark',
                background: {
                    paper: '#303030',
                    default: primary[800]
                },
                primary,
                secondary: {
                    main: '#FB797F'
                },
                tonalOffset: 0.2
            },
            overrides: {
                MuiAppBar: {
                    colorDefault: {
                        backgroundColor: primary[900]
                    }
                },
                MuiCard: {
                    root: {
                        borderRadius: '10px'
                    }
                },
                MuiCardHeader: {
                    root: {
                        padding: '24px 24px 0px 24px'
                    },
                    title: {
                        letterSpacing: '0.25px',
                        size: '20px',
                        color: primary[400]
                    },
                    subheader: {
                        fontSize: '12px',
                        letterSpacing: '0.21px',
                        lineHeight: '20px'
                    }
                },
                MuiCardContent: {
                    root: {
                        padding: '24px'
                    }
                },
                MuiFormControl: {
                    root: {
                        marginTop: '8px'
                    }
                },
                MuiTypography: {
                    root: {
                        color: primary[300]
                    }
                }
            }
        });
    return createMuiTheme({});
}

export default type =>
    getTheme({
        paletteType: type || 'dark'
    });
