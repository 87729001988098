import React, { useEffect, useContext } from 'react';

import ViewWrapper from 'Components/ViewWrapper';
import PanelWrapper from 'Components/PanelWrapper';
import Typography from '@material-ui/core/Typography';
import ThemeContext from '../../ThemeContext';

const PAGE_TITLE = 'Forecasting';

const ComingSoonPage = () => {
    const { title, setTitle } = useContext(ThemeContext);

    useEffect(() => {
        if (title !== PAGE_TITLE) {
            setTitle(PAGE_TITLE);
        }
    }, []);

    return (
        <ViewWrapper>
            <div
                data-testid="ComingSoonPage__div"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexShrink: 0,
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '32px'
                }}
            >
                <PanelWrapper>
                    <Typography
                        data-testid="ComingSoonPage__typography"
                        variant="h3"
                        size="48px"
                        color="primary"
                    >
                        Coming Soon!
                    </Typography>
                </PanelWrapper>
            </div>
        </ViewWrapper>
    );
};

export default ComingSoonPage;
