const initialState = {
    hasAccess: [],
    programList: [],
    creatingPod: false,
    createdExternalUser: false,
    error: '',
    lastRefresh: null,
    sortTeamBy: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'APPLICATION_SETTINGS':
            if (action.payload) {
                return {
                    ...state,
                    ...action.payload
                };
            }
            return { ...state };
        case 'CREATE_NEW_POD_PENDING':
            return {
                ...state,
                creatingPod: true,
                error: ''
            };
        case 'CREATE_NEW_POD_FULFILLED': {
            const newPod = action.payload.settings;
            const parent = state.programList.indexOf(
                state.programList.find(prog => prog.programId === newPod.parent)
            );

            const newProgramList = [...state.programList];
            newProgramList[parent].pods = [
                ...newProgramList[parent].pods,
                {
                    id: newPod.id,
                    name: newPod.prettyName
                }
            ];
            return {
                ...state,
                creatingPod: false,
                hasAccess: [
                    ...state.hasAccess,
                    {
                        programId: newPod.id,
                        role: 'admin'
                    }
                ],
                programList: newProgramList
            };
        }
        case 'SET_SORT_TEAM_BY':
            return {
                ...state,
                sortTeamBy: action.payload.sortBy
            };
        default:
            return state;
    }
};
