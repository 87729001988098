import React from 'react';

const ThemeContext = React.createContext({
    type: 'dark',
    setType: () => {},
    collapsed: false,
    setCollapsed: () => {},
    mobileSidebarOpen: false,
    setMobileSidebarOpen: () => {},
    title: '',
    setTitle: () => {}
});

export default ThemeContext;
