const initialState = {
    customFieldsData: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'GRAPH_DATA':
            return {
                ...state,
                ...action.payload
            };
        case 'CLEAR_GRAPH_DATA':
            return initialState;
        case 'SET_CUSTOM_FIELDS_DATA':
            return {
                ...state,
                customFieldsData: action.payload
            };
        default:
            return state;
    }
};
