/**
 * For managing firebase auth state and login
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import Loader from 'Components/Loader';
import { FB as firebase } from '../actions/actionsFirebase';
import { login } from '../actions';

const Auth = ({ children }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const userLoggedIn = useSelector(state => !!state.loggedUser.email);
    const [waitAuthCheck, setWaitAuthCheck] = useState(!userLoggedIn);

    const firebaseCheck = () =>
        new Promise(resolve =>
            firebase.auth().onAuthStateChanged(async user => {
                if (user) {
                    enqueueSnackbar('Logging in...', {
                        variant: 'default',
                        autoHideDuration: 500,
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center'
                        }
                    });

                    await dispatch(login(user));

                    resolve();
                } else {
                    resolve();
                }
            })
        );

    useEffect(() => {
        if (!userLoggedIn) {
            if (waitAuthCheck) {
                // First mount (logging in)
                firebaseCheck().then(() => {
                    setWaitAuthCheck(false);
                });
            }
        } else {
            // Login succesful
            enqueueSnackbar('User session restored!', {
                variant: 'success',
                autoHideDuration: 1000,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
        }
    }, [userLoggedIn]);

    return waitAuthCheck ? (
        <Loader id="auth__loader" fullScreen iconSize="large" message="Authenticating..." />
    ) : (
        <>{children}</>
    );
};

Auth.propTypes = {
    children: PropTypes.element.isRequired
};

export default Auth;
