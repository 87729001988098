import produce from 'immer';

const initialState = {
    data: null,
    fetching: false,
    lazyLoadSuccess: false,
    error: ''
};

/* eslint-disable default-case, no-param-reassign */
export default (state = initialState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case 'FETCH_PRODUCTIVITY_DATA_PENDING':
                draft.data = null;
                break;
            case 'FETCH_METRICS_PENDING':
                draft.data = null;
                draft.fetching = true;
                break;
            case 'FETCH_METRICS_FULFILLED':
                draft.data = action.payload.metricsData;
                draft.fetching = false;
                break;
            case 'FETCH_METRICS_REJECTED':
                draft.data = null;
                draft.fetching = false;
                draft.error = action.payload.error;
                break;
            default:
        }
    });
};
