import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

const whenFocusTab = lastRefresh => {
    if (!lastRefresh) return;
    const last = moment.unix(lastRefresh);
    const now = moment().unix();
    const diff = moment.unix(now).diff(last, 'hours');
    if (diff >= 1) {
        window.location.reload(false);
    }
};

const OnFocus = () => {
    const { lastRefresh } = useSelector(state => state.applicationSettings);

    useEffect(() => {
        window.addEventListener('focus', () => {
            whenFocusTab(lastRefresh);
        });
        return () => {
            window.removeEventListener('focus', () => {
                // This is intentional
            });
        };
    });

    return true;
};

export default OnFocus;
