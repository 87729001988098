import { median } from '../../higherOrderFunctions';

const adjustHeatmapRanges = valuesArray => {
    const min = Math.min(...valuesArray);
    const mid = median(valuesArray);
    const max = Math.max(...valuesArray);

    return {
        gradient: '0',
        color: [
            {
                code: '#c0e0c3',
                minvalue: min > 0 ? min * 2 * -1 : -1,
                maxvalue: Math.floor(min),
                label: 'Very Low'
            },
            {
                code: '#90c695',
                minvalue: Math.floor(min + 1),
                maxvalue: Math.floor(mid / 2 + 1),
                label: 'Low'
            },
            {
                code: '#efe48d',
                minvalue: Math.floor(mid / 2 + 1),
                maxvalue: Math.floor(mid + 1),
                label: 'Medium'
            },
            {
                code: '#fba969',
                minvalue: Math.floor(mid + 1),
                maxvalue: Math.floor(max / 2 + 1),
                label: 'High'
            },
            {
                code: '#e08283',
                minvalue: Math.floor(max / 2 + 1),
                maxvalue: max * 2,
                label: 'Very High'
            }
        ]
    };
};

export default grouppedData => {
    const formattedData = [];
    grouppedData.forEach(dailyData => {
        if (!dailyData) return null;

        const { dataLine, series } = dailyData;

        const seriesName = Object.keys(series);

        if (dataLine.length === 0 || seriesName.length === 0) return true;

        const columns = {
            column: dataLine.map(value => {
                return { id: value, label: value };
            })
        };

        const rows = {
            row: seriesName.map(serieName => {
                return { id: serieName, label: serieName };
            })
        };

        const data = [];
        let valuesArray = [];
        seriesName.forEach(serieName => {
            valuesArray = valuesArray.concat(series[serieName]);
        });

        const colorrange = adjustHeatmapRanges(valuesArray);

        seriesName.forEach(serieName => {
            dataLine.forEach((column, columnIndex) => {
                let value = series[serieName][columnIndex];

                // value can be -1 depending on ZD's responses
                // meaning that there is no data for this specific
                // spot and therefore should be hidden within the rendered
                // chart
                if (value === -1 || value === null) value = 0;

                let currentColorRange;
                colorrange.color.forEach(colorObject => {
                    if (value >= colorObject.minvalue && value <= colorObject.maxvalue)
                        currentColorRange = colorObject.label;
                });

                data.push({
                    rowid: serieName,
                    columnid: column,
                    displayvalue: value,
                    value,
                    colorrangelabel: currentColorRange
                });
            });
        });

        const dataset = [{ data }];

        const dataSource = {
            dataset,
            columns,
            rows,
            colorrange
        };

        const returnObject = {
            ...dataSource,
            dayKey: dailyData.dayKey
        };

        formattedData.push(returnObject);
        return null;
    });

    return formattedData;
};
