export default [
    {
        name: 'general',
        theme: {
            base: {
                chart: {
                    paletteColors:
                        '#ffffff,#ff9e00,#90C695, #05583e, #f47d54, #7d6cb7, #104c6d, #e08283, #fba969, #50bccd, #cc6050',
                    baseFontColor: '#bdbdbd',
                    captionFontSize: '21',
                    captionFontBold: '0',
                    subcaptionFontSize: '16',
                    subcaptionFontBold: '0',
                    captionFont:
                        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                    subcaptionFont:
                        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                    subCaptionFontColor: '#a8a8a8',
                    showBorder: '0',
                    divLineColor: '#808080',
                    labelDisplay: 'rotate',
                    bgColor: '#303030',
                    bgAlpha: '100',
                    showShadow: '0',
                    canvasBgColor: '#303030',
                    canvasBorderAlpha: '0',
                    useplotgradientcolor: '0',
                    useRoundEdges: '0',
                    showPlotBorder: '0',
                    showAlternateHGridColor: '0',
                    showAlternateVGridColor: '0',
                    toolTipBorderThickness: '0',
                    toolTipBgColor: '#303030',
                    toolTipBgAlpha: '70',
                    toolTipBorderRadius: '2',
                    toolTipPadding: '5',
                    legendBgAlpha: '0',
                    legendBorderAlpha: '0',
                    legendShadow: '0',
                    legendItemFontSize: '10',
                    divlineAlpha: '100',
                    divlineThickness: '0.75',
                    divLineGapLen: '1',
                    vDivLineDashGap: '1',
                    showHoverEffect: '1',
                    drawAnchors: '1',
                    crossLineColor: '#7f7f7f',
                    captionAlignment: 'left',
                    captionOnTop: '1',
                    anchorRadius: '4',
                    legendScrollBgColor: '#303030'
                }
            }
        }
    },
    {
        name: 'general-secondary',
        theme: {
            base: {
                chart: {
                    paletteColors:
                        '#ffffff,#ff9e00,#ffffff,#ff9e00,#90C695, #05583e, #f47d54, #7d6cb7, #104c6d, #e08283, #fba969, #50bccd, #cc6050',
                    baseFontColor: '#bdbdbd',
                    captionFontSize: '21',
                    captionFontBold: '0',
                    subcaptionFontSize: '16',
                    subcaptionFontBold: '0',
                    captionFont:
                        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                    subcaptionFont:
                        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                    subCaptionFontColor: '#a8a8a8',
                    showBorder: '0',
                    bgColor: '#303030',
                    bgAlpha: '100',
                    divLineColor: '#808080',
                    labelDisplay: 'rotate',
                    showShadow: '0',
                    canvasBgColor: '#303030',
                    canvasBorderAlpha: '0',
                    useplotgradientcolor: '0',
                    useRoundEdges: '0',
                    showPlotBorder: '0',
                    showAlternateHGridColor: '0',
                    showAlternateVGridColor: '0',
                    toolTipBorderThickness: '0',
                    toolTipBgColor: '#303030',
                    toolTipBgAlpha: '70',
                    toolTipBorderRadius: '2',
                    toolTipPadding: '5',
                    legendBgAlpha: '0',
                    legendBorderAlpha: '0',
                    legendShadow: '0',
                    legendItemFontSize: '10',
                    divlineAlpha: '100',
                    divlineColor: '#bdbdbd',
                    divlineThickness: '1',
                    divLineGapLen: '1',
                    showHoverEffect: '1',
                    drawAnchors: '1',
                    drawCrossLine: '1',
                    crossLineColor: '#7f7f7f',
                    exportEnabled: '1',
                    captionAlignment: 'left',
                    captionOnTop: '1',
                    anchorRadius: '4',
                    legendScrollBgColor: '#303030'
                }
            }
        }
    },
    {
        name: 'heatmap',
        theme: {
            base: {
                chart: {
                    paletteColors:
                        '#DCDCDC,#F3AD3D,#90C695, #05583e, #f47d54, #7d6cb7, #104c6d, #e08283, #fba969, #50bccd, #cc6050',
                    baseFontColor: '#bdbdbd',
                    valueFontColor: '#000000',
                    captionFontSize: '21',
                    captionFontBold: '0',
                    subcaptionFontSize: '16',
                    subcaptionFontBold: '0',
                    captionFont:
                        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                    subcaptionFont:
                        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                    subCaptionFontColor: '#a8a8a8',
                    showBorder: '0',
                    divLineColor: '#808080',
                    labelDisplay: 'rotate',
                    bgColor: '#303030',
                    bgAlpha: '100',
                    showShadow: '0',
                    canvasBgColor: '#303030',
                    canvasBorderAlpha: '0',
                    useplotgradientcolor: '0',
                    useRoundEdges: '0',
                    showPlotBorder: '0',
                    showAlternateHGridColor: '0',
                    showAlternateVGridColor: '0',
                    toolTipBorderThickness: '0',
                    toolTipBgColor: '#303030',
                    toolTipBgAlpha: '70',
                    toolTipBorderRadius: '2',
                    toolTipPadding: '5',
                    legendBgAlpha: '0',
                    legendBorderAlpha: '0',
                    legendShadow: '0',
                    legendItemFontSize: '10',
                    divlineAlpha: '100',
                    divlineThickness: '0.25',
                    divLineGapLen: '1',
                    vDivLineDashGap: '1',
                    showHoverEffect: '1',
                    drawAnchors: '1',
                    drawCrossLine: '1',
                    crossLineColor: '#7f7f7f',
                    captionAlignment: 'left',
                    captionOnTop: '1',
                    anchorRadius: '4',
                    legendScrollBgColor: '#303030'
                }
            }
        }
    },
    {
        name: 'therciary',
        theme: {
            base: {
                chart: {
                    paletteColors: '#f47d54, #7d6cb7, #104c6d, #e08283, #fba969, #50bccd, #cc6050',
                    baseFontColor: '#bdbdbd',
                    captionFontSize: '21',
                    captionFontBold: '0',
                    subcaptionFontSize: '16',
                    subcaptionFontBold: '0',
                    captionFont:
                        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                    subcaptionFont:
                        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                    subCaptionFontColor: '#a8a8a8',
                    showBorder: '0',
                    bgColor: '#303030',
                    bgAlpha: '100',
                    divLineColor: '#808080',
                    labelDisplay: 'rotate',
                    showShadow: '0',
                    canvasBgColor: '#303030',
                    canvasBorderAlpha: '0',
                    useplotgradientcolor: '0',
                    useRoundEdges: '0',
                    showPlotBorder: '0',
                    showAlternateHGridColor: '0',
                    showAlternateVGridColor: '0',
                    toolTipBorderThickness: '0',
                    toolTipBgColor: '#eaeaea',
                    toolTipBgAlpha: '70',
                    toolTipBorderRadius: '2',
                    toolTipPadding: '5',
                    legendBgAlpha: '0',
                    legendBorderAlpha: '0',
                    legendShadow: '0',
                    legendItemFontSize: '10',
                    divlineAlpha: '100',
                    divlineColor: '#eaeaea',
                    divlineThickness: '1',
                    divLineGapLen: '1',
                    showHoverEffect: '1',
                    drawAnchors: '1',
                    drawCrossLine: '1',
                    crossLineColor: '#7f7f7f',
                    exportEnabled: '1',
                    captionAlignment: 'left',
                    captionOnTop: '1',
                    anchorRadius: '4',
                    legendScrollBgColor: '#fcfcfc'
                }
            }
        }
    }
];
