import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProgramRedirect = ({ children }) => {
    const location = useLocation();
    const history = useHistory();
    const user = useSelector(state => state.loggedUser);

    useEffect(() => {
        if (user.email) {
            const date = moment().format('MM_DD_YY');
            if (location.pathname === '/') {
                history.push({
                    pathname: `/program/${user.program}/livestats/general`,
                    search: `?startDate=${date}&endDate=${date}&displayMode=live`
                });
            }
        }
    }, [location]);

    return <>{children}</>;
};

ProgramRedirect.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default ProgramRedirect;
