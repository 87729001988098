import _forOwn from 'lodash/forOwn';
import _clone from 'lodash/clone';
import _reduce from 'lodash/reduce';
import _pick from 'lodash/pick';
import _isEmpty from 'lodash/isEmpty';
import formatTeamLinegraphData from '../modules/charts/linegraph/formatTeamData';
import formatQueueHeatmapData from '../modules/charts/heatmap/formatQueueHeatmapData';
import formatQueueData from '../modules/charts/linegraph/formatQueueData';
import formatTeamMetrics from '../modules/charts/linegraph/formatTeamMetrics';
import formatCustomFields from '../modules/charts/linegraph/formatCustomFields';
import {
    sumObjectsByKey,
    stringToCamelCase,
    camelCaseToWords,
    textToTitle
} from '../modules/higherOrderFunctions';
import { customGet } from '../services/requests';
import Types from './types';
import dataByProperty from './dataByProperty';
import * as firebase from './actionsFirebase';

export { formatTeamMetrics, formatQueueData };

const drilldownColumns = {
    teamAndUser: [
        { title: 'Day', field: 'day' },
        { title: 'Hour', field: 'hour' },
        { title: 'Name', field: 'name' },
        { title: 'Touches', field: 'touches' },
        { title: 'Goal', field: 'goal' },
        { title: 'Solved', field: 'solved' },
        { title: 'Pending', field: 'pending_general' },
        { title: 'Open', field: 'open_general' }
    ],
    teamAndTicketIds: [
        { title: 'Name', field: 'name' },
        { title: 'Solved', field: 'solved' },
        { title: 'Pending', field: 'pending' },
        { title: 'Open', field: 'open' }
    ],
    ticketStatuses: [
        { title: 'Ticket Id', field: 'id' },
        { title: 'Status', field: 'status' }
    ]
};

export const clearGraphData = async () => {
    return async dispatch => {
        return dispatch({ type: Types.CLEAR_GRAPH_DATA });
    };
};

export const setHeatmapViewLoadData = async type => {
    return async (dispatch, getState) => {
        const state = getState();
        const { productivityData, programData } = state;
        const { globalProgram } = programData;
        const { displayMode, data, daily, startDate, endDate } = productivityData;

        const payload = {};
        const formatterProgramData = {
            productivityData: data,
            dailyProductivityData: daily,
            globalProgram,
            startDate,
            endDate
        };

        payload.data = await formatQueueHeatmapData.formatChartData(
            formatterProgramData,
            type,
            displayMode
        );

        if (type === 'daily') {
            payload.graphDataType = 'dailyHeatmapViewLoadData';
        } else {
            payload.graphDataType = 'totalHeatmapViewLoadData';
        }

        return dispatch({ type: Types.SET_VIEW_LOAD_DATA, payload });
    };
};

export const setLinegraphViewLoadData = async type => {
    return async (dispatch, getState) => {
        const state = getState();
        const { productivityData, programData } = state;
        const { globalProgram } = programData;
        const { displayMode, data, daily, startDate, endDate } = productivityData;

        const payload = {};
        const formatterProgramData = {
            productivityData: data,
            dailyProductivityData: daily,
            globalProgram,
            startDate,
            endDate
        };

        payload.data = await formatQueueData.formatChartData(
            formatterProgramData,
            type,
            displayMode
        );

        if (type === 'daily') {
            payload.graphDataType = 'dailyLinegraphViewLoadData';
        } else {
            payload.graphDataType = 'totalLinegraphViewLoadData';
        }

        return dispatch({ type: Types.SET_VIEW_LOAD_DATA, payload });
    };
};

export const setTeamResolutionTime = async () => {
    return async (dispatch, getState) => {
        const state = getState();
        const { productivityData, programData, metrics } = state;
        const { globalProgram } = programData;
        const { displayMode, data, startDate, endDate, daily } = productivityData;

        return dispatch({
            type: Types.SET_RESOLUTION_TIME_DATA,
            payload: formatTeamMetrics.formatChartData(
                {
                    productivityData: data,
                    dailyProductivityData: daily,
                    globalProgram,
                    startDate,
                    endDate
                },
                metrics.data,
                displayMode,
                dataByProperty['Resolution Time']
            )
        });
    };
};

export const setTeamReplyTime = async () => {
    return async (dispatch, getState) => {
        const state = getState();
        const { productivityData, programData, metrics } = state;
        const { globalProgram } = programData;
        const { displayMode, data, daily, startDate, endDate } = productivityData;

        const payload = formatTeamMetrics.formatChartData(
            {
                productivityData: data,
                dailyProductivityData: daily,
                globalProgram,
                startDate,
                endDate
            },
            metrics.data,
            displayMode,
            dataByProperty['Reply Time']
        );

        return dispatch({ type: Types.SET_REPLY_TIME_DATA, payload });
    };
};

export const setCustomFieldsData = async (displayMode, startDate, endDate) => {
    return async (dispatch, getState) => {
        const state = getState();
        const { productivityData } = state;
        const { data } = productivityData;

        const payload = await formatCustomFields(data, displayMode, startDate, endDate);
        return dispatch({ type: Types.SET_CUSTOM_FIELDS_DATA, payload });
    };
};

const drillDownTeamProductivityByUser = (nodeWithProductivity, timeKey, teamData) => {
    const totalDataNodes = [];
    _forOwn(nodeWithProductivity, (subNode, key) => {
        let allUsersObject = {};
        let userProductivityObjects = 0;
        _forOwn(subNode, productivityObject => {
            if (productivityObject.type !== 'userProd') return;
            const userProductivity = _clone(productivityObject);
            const userData = Object.values(teamData).find(
                user => user.email === userProductivity.email
            );
            userProductivity[timeKey] = key.split('_').join('/');
            userProductivity.name = userData
                ? `${userData.firstName}${userData.lastName ? ` ${userData.lastName}` : ''}`
                : textToTitle(
                      userProductivity.email
                          .split('@')[0]
                          .split('.')
                          .join(' ')
                  );
            totalDataNodes.push(userProductivity);

            allUsersObject = sumObjectsByKey(allUsersObject, userProductivity);
            userProductivityObjects += 1;
        });

        if (userProductivityObjects > 1) {
            allUsersObject[timeKey] = key.split('_').join('/');
            allUsersObject.name = 'All Users';
            totalDataNodes.push(allUsersObject);
        }
    });

    if (timeKey === 'day') {
        const totalData = _reduce(totalDataNodes, (accumulator, currentValue) => {
            if (currentValue.name === 'All Users') return accumulator;
            return sumObjectsByKey(accumulator, currentValue);
        });
        totalData[timeKey] = '';
        totalData.name = 'Total';
        totalDataNodes.push(totalData);
    }
    return totalDataNodes;
};

// this takes either day keys or hour keys and returns their added values per day or hour
const drillDownProductivitySet = (nodeWithProductivity, timeKey, teamData) => {
    return drillDownTeamProductivityByUser(nodeWithProductivity, timeKey, teamData); // without affecting the current application's aesthetics
};

const dateString = (dayData, startDate) => {
    const data = dayData;
    data.day = startDate.split('_').join('/');
    return data;
};

export const drillDownTeamData = (teamData, productivityData, displayMode, startDate, endDate) => {
    const tableData = {
        columns: [],
        data: []
    };

    if (displayMode === 'live') {
        tableData.columns = drilldownColumns.teamAndUser;
        const hoursProductivityData = productivityData[startDate].byHour;
        const startDayData = drillDownProductivitySet(hoursProductivityData, 'hour', teamData);
        startDayData.forEach(dayData => {
            const data = dayData;
            data.day = startDate.split('_').join('/');
        }, startDayData);
        tableData.data = startDayData;
    } else if (displayMode === 'overlap') {
        tableData.columns = drilldownColumns.teamAndUser;
        const startDayProductiveHours = productivityData[startDate].byHour;
        let startDayData = drillDownProductivitySet(startDayProductiveHours, 'hour', teamData);
        startDayData = startDayData.map(dayData => {
            return dateString(dayData, startDate);
        });
        const endDayProductiveHours = productivityData[endDate].byHour;
        let endDayData = drillDownProductivitySet(endDayProductiveHours, 'hour', teamData);
        endDayData = endDayData.map(dayData => {
            return dateString(dayData, startDate);
        });
        tableData.data = startDayData.concat(endDayData);
    } else if (displayMode === 'range') {
        tableData.columns = drilldownColumns.teamAndUser.filter(val => val.field !== 'hour');
        tableData.data = drillDownProductivitySet(productivityData, 'day', teamData);
    }
    return tableData;
};

const extractTicketsWithStatus = (ticketsArray, status) => {
    if (ticketsArray.length === 0) return [];
    return ticketsArray.map(id => ({ id, status }));
};

export const drillDownTeamDataWithTicketIds = async (
    programId,
    teamData,
    timestamp,
    displayMode
) => {
    const tableData = {
        columns: [],
        data: []
    };

    if (displayMode === 'live') {
        tableData.columns = drilldownColumns.teamAndTicketIds;
        const params = {
            programId,
            timestamp,
            users: Object.values(teamData)
                .map(user => user.email)
                .join(',')
        };
        const productivityDataForThisHour = await customGet('/stats/tickets', params);
        const formatedProductivity = productivityDataForThisHour.map(
            ({ email, solved = [], pending = [], open = [] }) => {
                const userObject = Object.values(teamData).find(user => user.email === email);
                const name = `${userObject.firstName} ${userObject.lastName || ''}`;

                const subDrilldownTableData = [
                    ...extractTicketsWithStatus(solved, 'solved'),
                    ...extractTicketsWithStatus(pending, 'pending'),
                    ...extractTicketsWithStatus(open, 'open')
                ];

                return {
                    name,
                    solved: solved.length,
                    pending: pending.length,
                    open: open.length,
                    subDrilldownTable: {
                        data: subDrilldownTableData,
                        columns: drilldownColumns.ticketStatuses
                    }
                };
            }
        );

        tableData.data = formatedProductivity;
    }

    return tableData;
};

const inferColumnsFromObject = obj => {
    if (!obj) return [];
    return Object.keys(obj).map(objectKey => {
        const keyAsNormalizedText = camelCaseToWords(objectKey);
        return { title: keyAsNormalizedText, field: objectKey };
    });
};

export const drilldownGenericLinegraph = (
    graphData,
    displayMode,
    startDate,
    endDate,
    email,
    user = 'All Users'
) => {
    const tableData = {
        columns: [],
        data: []
    };
    let graphValue = graphData;

    if (email) {
        graphValue = graphData.filter(dataByUser => dataByUser.email === email)[0].productivity;
    }

    // this is to standarize the fact that some come wrapped in arrays, instead of creating a separate similar case
    const graphDataArray = Array.isArray(graphValue) ? graphValue : [graphValue];

    graphDataArray.forEach(graphDataDay => {
        const hourObjects = graphDataDay.categories[0].category;
        const hourNodes = hourObjects.map((time, index) => {
            const hourNode = {};
            if (displayMode === 'range') {
                hourNode.day = time.label.split('_').join('/');
            } else {
                if (graphValue.dayKey) hourNode.day = graphDataDay.dayKey.split('_').join('/');
                hourNode.hour = time.label;
            }
            hourNode.name = user;
            graphDataDay.dataset.forEach(currentSeries => {
                const seriesKey = stringToCamelCase(currentSeries.seriesname);
                const seriesVal = currentSeries.data[index].value; // the value in the same index of the hour
                hourNode[seriesKey] = seriesVal;
            });
            return hourNode;
        });

        tableData.data = tableData.data.concat(hourNodes);
    });
    // infer titles here:
    tableData.columns = inferColumnsFromObject(tableData.data[0]);
    return tableData;
};

export const drillDownHeatmap = graphData => {
    const tableData = {
        columns: [],
        data: []
    };

    const graphDataArray = Array.isArray(graphData) ? graphData : [graphData];
    graphDataArray.forEach(graphDataDay => {
        const dataSets = graphDataDay.dataset[0].data;
        const hourNodes = {};

        dataSets.forEach(dataSet => {
            if (!hourNodes[dataSet.columnid]) {
                hourNodes[dataSet.columnid] = { day: graphDataDay.dayKey };
                hourNodes[dataSet.columnid] = { hour: dataSet.columnid };
            }
            hourNodes[dataSet.columnid][dataSet.rowid] = dataSet.displayvalue;
        });

        tableData.data = tableData.data.concat(Object.values(hourNodes));
    });
    tableData.columns = inferColumnsFromObject(tableData.data[0]);
    return tableData;
};

const drilldownSingleUser = (name, email, nodeWithUserProductivity, timeKey) => {
    const userDataNodes = [];
    _forOwn(nodeWithUserProductivity, (subNode, key) => {
        const subNodeValues = Object.values(subNode);
        const userData = subNodeValues.find(person => person.email === email);
        if (!userData) return;
        userData.name = name;
        userData[timeKey] = key.split('_').join('/');
        userDataNodes.push(userData);
    });
    return userDataNodes;
};

export const drilldownUserData = (
    productivityData,
    displayMode,
    startDate,
    endDate,
    email,
    name
) => {
    const tableData = {
        columns: [],
        data: []
    };

    tableData.columns = drilldownColumns.teamAndUser;

    if (displayMode === 'live') {
        const productivityNode = productivityData[startDate].byHour;
        const startDayData = drilldownSingleUser(name, email, productivityNode, 'hour');
        startDayData.forEach(dayData => {
            const data = dayData;
            data.day = startDate.split('_').join('/');
        }, startDayData);
        tableData.data = startDayData;
    } else if (displayMode === 'overlap') {
        const startProdData = productivityData[startDate].byHour;
        let startDateUserProductivityNode = drilldownSingleUser(name, email, startProdData, 'hour');
        startDateUserProductivityNode = startDateUserProductivityNode.map(dayData => {
            return dateString(dayData, startDate);
        });

        const endProdData = productivityData[endDate].byHour;
        let endDateUserProductivityNode = drilldownSingleUser(name, email, endProdData, 'hour');
        endDateUserProductivityNode = endDateUserProductivityNode.map(dayData => {
            const data = dayData;
            data.day = endDate.split('_').join('/');
            return data;
        });

        tableData.data = endDateUserProductivityNode.concat(startDateUserProductivityNode);
    } else if (displayMode === 'range') {
        tableData.columns = drilldownColumns.teamAndUser.filter(val => val.field !== 'hour');
        tableData.data = drilldownSingleUser(name, email, productivityData, 'day');
    }
    return tableData;
};

export const clearPodData = () => {
    return async dispatch => {
        return dispatch({ type: 'CLEAR_POD_DATA' });
    };
};

export const getPodHourlyThroughput = (programId, startDate, endDate, displayMode) => {
    return async dispatch => {
        return dispatch({
            type: 'POD_HOURLY_THROUGHPUT',
            payload: {
                data: {
                    id: programId
                },
                async promise() {
                    const globalProgram = await firebase.get(`programs/${programId}`);

                    const { settings, team } = globalProgram;
                    const { type, parent } = settings;

                    if (type === 'pod') {
                        const parentTeam = await firebase.get(`programs/${parent}/team`);
                        const podTeam = _pick(parentTeam, team);
                        globalProgram.team = podTeam;
                    }

                    const payload = await customGet(`/productivity/${displayMode}`, {
                        programId,
                        startDate,
                        endDate
                    });

                    const todayProductivity = payload.productivityData;

                    // delete null day object keys (when no productivity data for the day)
                    Object.keys(todayProductivity).forEach(
                        key => todayProductivity[key] == null && delete todayProductivity[key]
                    );

                    if (_isEmpty(todayProductivity)) {
                        return {
                            id: programId,
                            program: globalProgram,
                            data: null
                        };
                    }

                    const formatted = await formatTeamLinegraphData(
                        {
                            productivityData: todayProductivity,
                            dailyProductivityData: {},
                            globalProgram,
                            startDate,
                            endDate
                        },
                        displayMode
                    );

                    return {
                        id: programId,
                        program: globalProgram,
                        data: formatted.formattedData
                    };
                }
            }
        });
    };
};
