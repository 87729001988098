import Types from './types';
import { customGet } from '../services/requests';

export async function fetchProductivityData(programId, startDate, endDate, displayMode) {
    return async dispatch => {
        const payload = customGet(`/productivity/${displayMode}`, {
            programId,
            startDate,
            endDate,
            displayMode
        });

        return dispatch({ type: 'FETCH_PRODUCTIVITY_DATA', payload });
    };
}

export async function clearProductivityData() {
    return async dispatch => {
        return dispatch({ type: Types.CLEAR_PRODUCTIVITY_DATA });
    };
}
