import React, { Suspense } from 'react';
import { withRouter, Switch } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import Loader from 'Components/Loader';
import { routes } from '../../routers/AppRoutes';

const Layout = () => {
    return (
        <Switch>
            <Suspense fallback={<Loader />}>{renderRoutes(routes)}</Suspense>;
        </Switch>
    );
};

export default withRouter(Layout);
