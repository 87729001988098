import Types from './types';
import { customGet } from '../services/requests';

export async function fetchMetrics(programId, startDate, endDate, displayMode) {
    return async (dispatch, getState) => {
        const state = getState();
        const { id } = state.programData.globalProgram.settings;

        const payload = customGet(`/metrics/${displayMode}`, {
            programId: id,
            startDate,
            endDate
        });

        return dispatch({ type: 'FETCH_METRICS', payload });
    };
}

export async function clearMetrics() {
    return async dispatch => {
        return dispatch({ type: Types.CLEAR_METRICS });
    };
}
