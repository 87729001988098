import _find from 'lodash/find';
import formatToFusionChart from '../fusionChartsFormatters';

const getSeries = (dayData, hours, dayKey, viewLoadType) => {
    const queueLoads = {};

    hours.forEach(hour => {
        let index = dayData[hour];

        // convert to array
        index = Object.keys(index).map(key => {
            return index[key];
        });

        // filter out queue load
        index = _find(index, o => {
            return o.type === 'queueLoad';
        });

        if (index) {
            const queueNames = Object.keys(index);

            queueNames.forEach(queueName => {
                if (queueName !== 'type') {
                    let queueLoad = index[queueName];

                    if (typeof queueLoad === 'object' && queueLoad !== null) {
                        queueLoad = viewLoadType ? queueLoad[viewLoadType] : queueLoad.total;
                    }

                    if (Number.isNaN(queueLoad)) queueLoad = null;

                    if (!queueLoads[queueName]) queueLoads[queueName] = [];
                    queueLoads[queueName].push(queueLoad);
                }
            });
        }
    });

    return {
        dayKey,
        dataLine: hours,
        series: {
            ...queueLoads
        }
    };
};

const buildDayObject = (dayKey, dayData, viewLoadType) => {
    if (!dayData || (dayData && !dayData.byHour)) return null;
    const hours = Object.keys(dayData.byHour);
    return getSeries(dayData.byHour, hours, dayKey, viewLoadType);
};

const processAllDays = (productivityData, viewLoadType, displayMode, startDate, endDate) => {
    const groupedData = [];
    if (displayMode === 'range') {
        const days = Object.keys(productivityData).filter(
            day => day >= startDate && day <= endDate
        );
        return days.map(dayKey => {
            return {
                dayKey,
                series: {
                    ...productivityData[dayKey].queueLoad
                }
            };
        });
    }
    const days = Object.keys(productivityData);
    days.forEach(dayKey => {
        const dayData = productivityData[dayKey];
        const formattedDayData = buildDayObject(dayKey, dayData, viewLoadType);
        if (formattedDayData) {
            groupedData.push(formattedDayData);
        }
    });

    return groupedData;
};

const groupAllData = (productivityData, viewLoadType, displayMode, startDate, endDate) => {
    return processAllDays(productivityData, viewLoadType, displayMode, startDate, endDate);
};

const formatRangeData = (groupedData, viewLoadType) => {
    const dayLine = [];
    const timelines = {};

    groupedData.forEach(dateIndex => {
        dayLine.push(dateIndex.dayKey.replace(/_/g, '/').slice(0, 5));

        const dailySeries = dateIndex.series;

        Object.keys(dailySeries).forEach(serieKey => {
            if (serieKey !== 'type')
                if (typeof timelines[serieKey] === 'undefined')
                    timelines[serieKey] = [dailySeries[serieKey][viewLoadType]];
                else timelines[serieKey].push(dailySeries[serieKey][viewLoadType]);
        });
    });

    return {
        dataLine: dayLine,
        series: timelines,
        dayKey: groupedData[0].dayKey
    };
};

const formatChartData = (programData, viewLoadType, displayMode, alternateQueueData) => {
    let { productivityData } = programData;
    if (!productivityData) productivityData = alternateQueueData;

    if (!productivityData) return null;
    let filteredProductivityData = JSON.parse(JSON.stringify(productivityData));

    const { startDate, endDate } = programData;
    if (displayMode === 'range') {
        const rangedData = {};
        Object.keys(filteredProductivityData).forEach(date => {
            rangedData[date] = filteredProductivityData[date];
        });
        filteredProductivityData = rangedData;
    }

    const groupedData = groupAllData(
        filteredProductivityData,
        viewLoadType,
        displayMode,
        startDate,
        endDate
    );
    let formattedData;
    if (displayMode === 'range') {
        const rangedData = formatRangeData(groupedData, viewLoadType);
        formattedData = formatToFusionChart([rangedData], 'complex');
    } else formattedData = formatToFusionChart(groupedData, 'complex');

    return formattedData;
};

export default formatChartData;
