import moment from 'moment-timezone';
import Types from './types';
import { customGet, customPost, customPut, customDelete } from '../services/requests';

export async function fetchAllUsers() {
    const payload = await customGet('/users');
    return payload;
}

export async function fetchUserDashboardMetrics(programId, email, startDate, daily = false) {
    return customGet(
        `/stats?daily=${daily}&programId=${programId}&email=${email}&timestamp=${moment(
            startDate,
            'MM_DD_YY'
        ).unix()}`
    );
}

export async function updateFirebaseUser(rootName, user, isExternal) {
    const userObjectPath = isExternal ? `/users/external/${rootName}` : `/users/${rootName}`;
    const userObject = await customGet(userObjectPath);

    userObject.lastLogin = moment().format('MM/DD @ HH:mm');

    // support for pods feature (new role structure)
    if (!userObject.programs) {
        userObject.programs = {
            [userObject.program]: {
                programId: userObject.program,
                role: userObject.role || 'user'
            }
        };
    }
    userObject.rootName = rootName;

    userObject.profilePicture = user.picture;
    customPut(userObjectPath, { userObject });
}

export async function findExternalUser(email) {
    return customGet(`users/external`, { email });
}

export async function getUserData(user) {
    let isExternal = false;

    return customGet('/users', { email: user.email })
        .then(async foundUser => {
            let userData = { ...foundUser };

            if (!foundUser) {
                userData = await findExternalUser(user.email);
                isExternal = true;
            }

            // because { ...null } returns {} we have to check if empty and lodash is kinda slow
            if (
                !userData ||
                (Object.entries(userData).length === 0 && userData.constructor === Object)
            )
                return null;

            // support for pods feature (new role structure)
            if (!userData.programs) {
                userData.programs = {
                    [userData.program]: {
                        programId: userData.program,
                        role: userData.role
                    }
                };
            }

            if (!userData.euler) {
                userData.role = userData.programs[userData.program].role;
            }
            const { rootName } = userData;
            updateFirebaseUser(rootName, user, isExternal);

            return userData;
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.error(err);
            return null;
        });
}

/**
 * Gets user specific settings
 * @param {{ email: string, rootName: string, programs: string[] }} user User trying to login
 */
export async function getAppSettings(user) {
    return customGet(`users/${user.rootName}/programs`)
        .then(userPrograms => {
            let hasAccess = [];
            const programs = Object.keys(user.programs).map(progId => {
                const p = user.programs[progId];
                hasAccess.push(p);

                const program = userPrograms.find(prg => prg.settings.id === progId);

                if (!program) return false;

                const programPods = Object.keys(program.settings.pods || []);

                let pods = [];
                if (p.role === 'admin' || p.role === 'euler') {
                    pods = programPods.map(podId => ({
                        id: userPrograms.find(userPod => userPod.settings.id === podId).settings.id,
                        name: userPrograms.find(userPod => userPod.settings.id === podId).settings
                            .prettyName,
                        active: userPrograms.find(userPod => userPod.settings.id === podId).settings
                            .active
                    }));

                    hasAccess = hasAccess.concat(
                        pods.map(pod => ({ programId: pod.id, role: 'admin' }))
                    );
                }

                return {
                    ...p,
                    name: program.settings.prettyName,
                    pods
                };
            });

            return {
                programList: programs,
                lastRefresh: moment().format('X'),
                hasAccess
            };
        })
        .catch(err => {
            // eslint-disable-next-line no-console
            console.error(err);
            return null;
        });
}

export async function dispatchUserData(payload) {
    return async dispatch => {
        return dispatch({ type: Types.LOGGED_USER, payload });
    };
}

export async function createExternalUser(email, programId) {
    return async dispatch => {
        dispatch({
            type: Types.CREATE_EXTERNAL_USER,
            payload: customPost('users/external', {
                email,
                programId
            })
        });
    };
}

export async function removeExternalUser(email) {
    return customDelete('users/external', {
        email
    });
}
