import complexChartFormatter from './complexChartFormatter';
import heatmapChartFormatter from './heatmapChartFormatter';

const chartTypes = {
    complex: complexChartFormatter,
    heatmap: heatmapChartFormatter
};

export default (groupedData, chartType) => {
    return chartTypes[chartType](groupedData);
};
